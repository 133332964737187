import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";

export default () => {
  const [consultantProfiles, setConsultantProfiles] = useState([]);

  useEffect(() => {
    fetchConsultantProfiles();
  }, []);

  const fetchConsultantProfiles = () => {
    axios
      .get("/api/consultant-approval/get/sub-catg/consultant-list")
      .then((response) => {
        console.log("/sub-catg/consultant-list ===> ", response);
        setConsultantProfiles(response.data.data);
      })
      .catch((error) => {
        console.log("Error => ", error);
      });
  };
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding">
      <section className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding content">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pageContent table-responsive">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 NOPadding topMargin box-header with-border">
              <h4 className="col-lg-12 col-md-12 col-xs-12 col-sm-12 weighttitle NOPadding">
                Consultant Other Sub-category
              </h4>
            </div>
          </div>
          <Table
            data={consultantProfiles}
            fetchConsultantProfiles={fetchConsultantProfiles}
          />
        </div>
      </section>
    </div>
  );
};

const Table = (props) => {
  const [loader, setLoader] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [filter, setFilter] = useState({});

  const columns = [
    {
      key: "name",
      name: "Consultant Name",
      colSize: "w100",
      type: "string",
      isSortable: true,

    },
    {
      key: "enterpriseName",
      name: "Enterprise Name",
      colSize: "w100",
      type: "string",
      isSortable: true,

    },
    {
      key: "email",
      name: "Email",
      colSize: "w100",
      type: "string",
      isSortable: true,
    },
    {
      key: "category",
      name: "Main Category",
      colSize: "w100",
      type: "string",
      isSortable: true,
    },
    {
      key: "subCategory",
      name: "Sub Category",
      colSize: "w100",
      type: "string",
      isSortable: true,
    },
    {
      key: "status",
      name: "Status",
      colSize: "w100",
      type: "string",
      isSortable: true,
      children: (props) => <ShowStatus {...props} />,      
    },
    {
      key: "",
      name: "Action",
      colSize: "w100",
      type: "string",
      isSortable: true,
      children: (props) => <ModalBtn {...props} />,
    },
    {
      key: "remark",
      name: "Remark",
      colSize: "w100",
      type: "string",
      isSortable: true,
    },  ];

  const [page, setPage] = useState(1);
  let pages = 2;
  const getPaginatedEntries = () => {
    pages = props.data.length > 0 ? props.data.length - 1 : 1;
    return props.data.slice((page - 1) * pageLimit, page * pageLimit);
  };

  const handlePageLimit = (e) => {
    setPageLimit(parseInt(e.target.value));
  };

  const dataRows = getPaginatedEntries();

  const handleSort = (event) => {
    setFilter({
      ...filter,
      sort: { key: event.target.id, type: filter?.sort?.type === 1 ? 0 : 1 },
    });
  };

  return (
    <div className="pageContent px-5  pt-1">
      <div className="justify">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 table-responsive">
          <table className="table table-hover table-bordered table-striped table-height tableFixHead">
            <thead className="tempTableHeader">
              <tr>
                <th scope="col">#</th>
                {columns.map((column,i) => (
                  <th
                    key={i}
                    scope="col"
                    className={` ${
                      filter?.sort?.key === column.key && "text-primary"
                    }  text-uppercase `}
                  >
                    {column.name}
                      
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                dataRows
                ?
                  dataRows.length > 0 ? (
                    dataRows.map((data, index) => (
                      <tr key={index}>
                        <td className=" text-center align-middle">
                          {page === 1
                            ? index + 1
                            : `${
                                (page === 1 ? 1 : page - 1) * pageLimit + (index + 1)
                              }`}
                        </td>
                        {columns.map((column, index) => (
                          <td
                            key={index}
                            className={column.colSize && ` ${column.key === "status" ? "text-center" : "text-left"
                            } align-middle`}
                          >
                            {column.children ? (
                              <column.children
                                fetchConsultantProfiles={
                                  props.fetchConsultantProfiles
                                }
                                data={data}
                                title={data[column.key] ?? ""}
                              />
                            ) : (
                              data[column.key]
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) 
                  :
                    (
                      <tr className="trAdmin">
                      {loader ? (
                        <div className="loadingImg">
                          <img src="/images/loading.gif" />
                        </div>
                      ) : (
                        <td colSpan="9" className="noTempData textAlignCenter">No Record Found!</td>
                      )}
                      </tr>
                    )
              
                :
                  <tr className="trAdmin">
                      <td colSpan="9" className="noTempData textAlignCenter">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        <span className="sr-only">Loading...</span>                 
                      </td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
        <TablePagination
          currentPage={page}
          pages={pages}
          setPage={setPage}
          pageLimit={pageLimit}
          handlePageLimit={handlePageLimit}
        />
      </div>
    </div>
  );
};

const TablePagination = ({
  currentPage,
  pages,
  setPage,
  pageLimit,
  handlePageLimit,
}) => {
  const numPages = Math.ceil(pages / pageLimit);

  const renderPageBlocks = () => {
    return (
      <>
        {currentPage !== 1 && (
          <li className="page-item">
            <a className="page-link" href="#" onClick={goToPrevPage}>
              {currentPage - 1}
            </a>
          </li>
        )}
        <li className="page-item active" disabled>
          <a className="page-link" href="#">
            {currentPage}
          </a>
        </li>
        {currentPage < numPages && (
          <li className="page-item">
            <a className="page-link" href="#" onClick={goToNextPage}>
              {currentPage + 1}
            </a>
          </li>
        )}
      </>
    );
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < numPages) {
      setPage(currentPage + 1);
    }
  };

  const renderPrevPageBlocks = () => {
    return (
      <li className="page-item">
        <a className="page-link" href="#" onClick={() => setPage(1)}>
          &#171;
        </a>
      </li>
    );
  };

  const renderNextPageBlocks = () => {
    return (
      <li className="page-item">
        <a className="page-link" href="#" onClick={() => setPage(numPages)}>
          &raquo;
        </a>
      </li>
    );
  };

  const renderTotals = () => {
    return (
      <li className="mx-2 col-8">
        <h6>{`Showing ${currentPage} of ${numPages}`} Pages</h6>
      </li>
    );
  };

  return (
    <nav aria-label="Page navigation" className="text-center align-middle mb-5">
      <ul className="pagination col-12 d-flex justify-content-center flex-direction-row">
        {renderTotals()}
        {renderPrevPageBlocks()}
        {renderPageBlocks()}
        {renderNextPageBlocks()}
      </ul>
    </nav>
  );
};


const ModalBtn = (props) => {
  // console.log(props);
   const handleClick = () => {
      swal("Remark:", {
         content: "input",
         attributes: {
         placeholder: 'Please provide reason.',
         required: true
      },
      closeOnClickOutside: false,
      button: {
        text: "Save",
      },
      }).then((value) => {
        if(value){
          handleApprovalFeedback("Rejected", value);
        }else{
          swal(" ","Please provide reason.")
        }
      });
   }

   const handleApprovalFeedback = (status, remark) => {
      var formValues={
        consultantName  : props.data.name,
        consultant_id   : props.data.id,
        subCategory     : props.data.subCategory,
        status          : status,
        remark          : remark,
        category        : props.data.category,
        category_id     : props.data.category_id,
        email           : props.data.email,
        phoneNumber     : props.data.phoneNumber,
      }
      axios.patch("/api/consultant-approval/consultant/approve-others-sub-catg",formValues)
      .then((response) => {
        if (response.data.success && response.data.status==="Approved") {
          swal(" ",response.data.subCategory + " has been approved successfully");
        } else if(response.data.success && response.data.status==="Rejected"){
          swal(" ",response.data.subCategory + " has been rejected");
        } else if(response.data.duplicated){
          swal(" ",response.data.subCategory + " already exists in this Category");
        } else {
          swal(" ",response.data.subCategory + " has not updated");
        }
        props.fetchConsultantProfiles();
      })
      .catch((error) => {
        console.log("Error => ", error);
        swal(" ", "Response not submitted.");
      });
  };

   if(props.data.status === "Pending"){
      return <div style={{"width":"150px"}}>
         <button className='btn btn-success col-2' onClick={() => handleApprovalFeedback('Approved')} >Approve</button> &nbsp;
         <button className='btn btn-danger col-2' onClick={handleClick}>Reject</button>
      </div>;      
   }else{
      return null ;
   }

};


const ShowStatus = (props)=>{
   if(props.data.status === "Approved"){
      var textColour = "#28a745";
   }
   if(props.data.status === "Rejected"){
      var textColour = "#dc3545";
   }
   if(props.data.status === "Pending"){
      var textColour = "#ffc107";
   }
   return <div style={{fontWeight : "bold",fontSize:'15px',color:textColour}}> {props.data.status} </div>
}