import React, { Component } 	from 'react';
import $                    	from 'jquery';
import ReportsTemplate     	from './ReportsTemplate.js';
import axios                  from 'axios';
import "./Reports.css";
import 'font-awesome/css/font-awesome.min.css';

class ConsultantDataReport extends Component{ 
	constructor(props){
		super(props);
		this.state = {
			vendorArray 					: [],
			showDateWiseFilters 			: true, // to show DateWise filters set true else set false
			filterObject         		: {
													'dailyFilter' 				: true, // to show Daily Filter set true else set false
													'weeklyFilter' 			: true, // to show Weekly Filter set true else set false
													'monthlyFilter' 			: true, // to show Monthly Filter set true else set false
													'yearlyFilter' 			: true, // to show Yearly Filter set true else set false
													'customizedDateFilter' 	: true // to show Customized Date Filter set true else set false
			},
			showCustomizedFilters 		: false, // to add other customized filters set true else set false
			
			customizedFiltersArray  	: [
													//Array to add different customized filters
													{
														'inputLabel' 			: "Status", 			
														// 'inputType' 			: "select",
														'inputType' 			: "",
														'inputDefaultValue' 	: "--Select--",
														'inputPlaceholder' 	: "",
														'inputName' 			: "status",
														'inputArray' 			: [
																							{ name : 'status', value: 'New', label: 'New' },
																					  		{ name : 'status', value: 'Processing', label: 'Processing' },
																					  		{ name : 'status', value: 'Ready to Dispatch', label: 'Ready to Dispatch' },
																					  		{ name : 'status', value: 'On The Way', label: 'On The way' },
																					  		{ name : 'status', value: 'Delivered', label: 'Delivered' },
																					  		{ name : 'status', value: 'Cancelled', label: 'Cancelled' }
																					  ],
														'apiUrl' 				: "/api/"
													}
													
			],
			'currentActiveTab' 		: "Daily", //If showDateWiseFilters is true then set cuttentActiveTab 'Daily' or 'Weekly' or 'Monthly' or 'Yearly' or 'Customize' or leave it ""
			'reportTitle' 				: "Consultant Data Report", // Title or Heading of report
			'tableName' 				: "Consultant_Data_Report", // Title or Heading of report
			'tableDatas'        		: [],
			'reportData'        		: {},
			'tableData'         		: [],
			"startRange"        		: 0,
			"limitRange"        		: 10,
			"dataApiUrl"        		: "/api/reports/get/consultant-data",			
			"tableHeading"      		: {
													srNo    					: 'Sr No',
													enterpriseName     	: 'Enterprise Name', 
													enterprisePANGST		: 'Enterprise PAN & GST',
													address       			: 'Address',
													branches 				: 'Branches',
													entpMainCatg			: 'Enterprise Main category',
													entpSubCatg 			: 'Enterprise Sub category',
													entpExpertise 			: 'Enterprise Expertise',
													entpExperience			: 'Enterprise Experience',
													staffStrength			: 'Staff Strength',
													entpAwardsMembership	: 'Enterprise Awards & Memberships',
													consultantName			: 'Consultant Name',
													consultantExperience	: 'Consultant Experience',
													qualification			: 'Qualification',
													consultantPAN			: 'Consultant PAN',
													languagesKnown			: 'Languages Known',
													consultantMainCatg	: 'Consultant Main Category',
													consultantSubCatg		: 'Consultant Sub category',
													consultantExpertise	: 'Consultant Expertise',
													descriptionSkills		: 'Description & Skills',
													pastWorkExperience	: 'Past Work Experience',
													consAwardsMembership	: 'Consultant Awards & Memberships',
													leadTime					: 'Lead Time',
													IntroCallsFees			: 'Introductory Calls Fees',
													workingDays				: 'Working Days',
													onlineTime				: 'Online Time',
													selectedPlan			: 'Selected Plan',
													joinedasaConsultant  : 'Joined as a Consultant',
													invitedConsultant  	: 'Invited Consultant',
													registrationDate  	: 'Registration Date',
			},
      	tableObjects      		: {
							        paginationApply 			: true,
							        searchApply     			: true,
							        excelReportExport 			: false,
							        searchApplyPlaceholder 	: "Search by Consultant Name",
      	},
		}
		window.scrollTo(0, 0);
  	}
   
	render(){
		return(  
		  	// <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 marginTop11">			
			  	<ReportsTemplate 
			  		tableHeading 				= {this.state.tableHeading} 
			  		tableObjects 				= {this.state.tableObjects} 
			  		showDateWiseFilters 		= {this.state.showDateWiseFilters}
			  		filterObject 				= {this.state.filterObject}
			  		showCustomizedFilters 	= {this.state.showCustomizedFilters}
			  		customizedFiltersArray 	= {this.state.customizedFiltersArray}
			  		currentActiveTab 			= {this.state.currentActiveTab}
			  		reportTitle 				= {this.state.reportTitle}
			  		dataApiUrl 					= {this.state.dataApiUrl}
			  	/>  
		  	// </div>
		);
  	}
}

export default ConsultantDataReport