import React, { Component } from 'react';
import $ from 'jquery';
import ReportsTemplate from './ReportsTemplate.js';
import axios from 'axios';
import "./Reports.css";
import 'font-awesome/css/font-awesome.min.css';

class ConsultantSettlementReport  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendorArray                 : [],
            showDateWiseFilters         : true, // to show DateWise filters set true else set false
            filterObject                : {
                'dailyFilter'           : true, // to show Daily Filter set true else set false
                'weeklyFilter'          : true, // to show Weekly Filter set true else set false
                'monthlyFilter'         : true, // to show Monthly Filter set true else set false
                'yearlyFilter'          : true, // to show Yearly Filter set true else set false
                'customizedDateFilter'  : true // to show Customized Date Filter set true else set false
            },
            showCustomizedFilters       : false, // to add other customized filters set true else set false
            customizedFiltersArray      : [
                //Array to add different customized filters
                {
                    'inputLabel'        : "Status",
                    'inputType'         : "",
                    'inputDefaultValue' : "--Select--",
                    'inputPlaceholder'  : "",
                    'inputName'         : "status",
                    'inputArray'        : [
                        { name: 'status', value: 'New', label: 'New' },
                        { name: 'status', value: 'Processing', label: 'Processing' },
                        { name: 'status', value: 'Ready to Dispatch', label: 'Ready to Dispatch' },
                        { name: 'status', value: 'On The Way', label: 'On The way' },
                        { name: 'status', value: 'Delivered', label: 'Delivered' },
                        { name: 'status', value: 'Cancelled', label: 'Cancelled' }
                    ],
                    'apiUrl'            : "/api/"
                }

            ],
            'currentActiveTab'          : "Daily", //If showDateWiseFilters is true then set cuttentActiveTab 'Daily' or 'Weekly' or 'Monthly' or 'Yearly' or 'Customize' or leave it ""
            'reportTitle'               : "Consultant Settlement Report", // Title or Heading of report
            'tableName'                 : "Consultant_Settlement_Report", // Title or Heading of report
            'tableDatas'                : [],
            'reportData'                : {},
            'tableData'                 : [],
            "startRange"                : 0,
            "limitRange"                : 10,
            "dataApiUrl"                : "/api/reports/get/consultant-settlement",
            "tableHeading"              : {
                srNo                            : 'Sr. No',
                enterpriseID                    : 'Enterprise ID',
                enterpriseName                  : 'Enterprise Name',
                consultantID                    : 'Consultant ID',
                consultantName                  : 'Consultant Name',
                introductoryCallTokenID         : 'Introductory Call token ID',
                dateOfCall                      : 'Date of Call',
                consultantFees                  : 'Consultant Fees',
                discountGiven                   : 'Discount given',
                amountChargedToUser             : 'Amount Charged to User',
                amountPaidByUser                : 'Amount paid by user',
                gst                             : 'GST',
                shareOfPlatform                 : 'Share of platform',
                shareOfConsultant               : 'Share of consultant',
                whetherPaymentMadeToConsultant  : 'Whether payment made to consultant?',
                paymentReferenceID              : 'Payment Reference ID',
                paymentDate                     : 'Payment Date',
            },
            tableObjects: {
                paginationApply         : true,
                searchApply             : true,
                excelReportExport       : false,
                searchApplyPlaceholder  : "Search by Consultant Name",
            },
        }
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <ReportsTemplate
                tableHeading                ={this.state.tableHeading}
                tableObjects                ={this.state.tableObjects}
                showDateWiseFilters         ={this.state.showDateWiseFilters}
                filterObject                ={this.state.filterObject}
                showCustomizedFilters       ={this.state.showCustomizedFilters}
                customizedFiltersArray      ={this.state.customizedFiltersArray}
                currentActiveTab            ={this.state.currentActiveTab}
                reportTitle                 ={this.state.reportTitle}
                dataApiUrl                  ={this.state.dataApiUrl}
            />
        );
    }
}

export default ConsultantSettlementReport