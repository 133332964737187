import React,{Component} from 'react';
import { render } from 'react-dom';
import { Bar } from "react-chartjs-2";
//import Chart from 'chart.js/auto';
import axios             from 'axios';

const options = {
    scales: {
      xAxes: [{
        stacked: true,
      }],
      yAxes: [{
        stacked: true,
          }],
    },
    plugins:{labels:true},
    responsive: true,
    maintainAspectRatio: false     
};
export default class BarChart extends Component{
  
   constructor(props) {
      super(props);
      this.state = {
         boxColor    : props.boxColor,
         title       : props.title,
         display     : props.display,
         parameter      : [],
         totalData     : [],
         "data" : {
            labels: [],
            datasets: [{
               label: 'Estimated Cost',
               backgroundColor:'rgba(54, 162, 235, 1)',
               borderColor: 'rgba(54, 162, 235, 1)',
               borderWidth: 1,
               hoverBackgroundColor:'rgba(255, 206, 86, 1)',
               hoverBorderColor:'rgba(255, 206, 86, 1)',
               stack: '1',
               data: []
            }]
         }
      }
   }
   
   componentDidMount(){
      const userDetails  = localStorage.getItem("userDetails");
      if(userDetails){
         const userDetailsParse  = JSON.parse(userDetails);
         var user_id = userDetailsParse.user_id
      
         // console.log("this.props => ",this.props);
         if(this.props.display){
            this.setState({
               boxColor: this.props.boxColor,
               title: this.props.title,
               apiData : this.props.api,
               user_id: user_id,
               fromDate : this.props.fromDate,
               toDate : this.props.toDate,
            },()=>{
               this.getData(this.props.fromDate, this.props.toDate)})
         }
      }      
   }

   componentWillReceiveProps(nextProps){
      // console.log("this.props componentWillReceiveProps",this.props)
      this.getData(this.props.fromDate, this.props.toDate)
   }

  
   getData(fromDate,toDate){
      // var data = {...this.state.data};
      // console.log("this.state.apiData => ",this.state.apiData);

      if(this.state.apiData){
         var method = this.state.apiData.method;
         var path = this.state.apiData.path;
         //var formValues = this.state.apiData.formValues;
         //formValues.user_id = this.state.user_id
         axios({
            method: method,
            url: path+fromDate+"/"+toDate,
           // data:formValues
         })
        .then((response)=>{ 
            console.log("BarChart response.data => ",response)
            var parameter = [];
            var totalData = [];
            var totalBill = [];
            if(response.data && response.data.totals && response.data.totals.length>0){
               response.data.totals.map((data,index)=>{ 
                  parameter.push(data.parameter);
                  totalData.push(data.totalData);
                  totalBill.push(data.totalBill);
               })
            }  
            if(parameter.length > 0){
               this.setState({parameter:parameter.slice(0,10),totalData:totalData.slice(0,10),totalBill:totalBill.slice(0,10)});
            }else{
               this.setState({parameter:[],totalData:[],totalBill:[]});
            }
         })
         .catch((error)=>{  
            console.log('error=>',error)      
         });
      }
   }
    
   render(){
      // console.log("this.state.parameter => ",this.state.parameter);
      return(
         <div className="col-lg-12  col-md-12 col-xs-12 col-sm-12  chartBox chartBoxNew">
            <h4 className="graphFont text-center"><b>{this.state.title}</b></h4>
            <div style={{ maxWidth: "450px" }}>
               <Bar
                  data={{
                     // Name of the variables on x-axies for each bar
                     labels: this.state.parameter && this.state.parameter.length>0 ? this.state.parameter : [],
                     datasets: [
                      {
                        // Label for bars
                        label: "Appointments",
                        // Data or value of your each variable
                        data: this.state.totalData && this.state.totalData.length>0 ? this.state.totalData : [],
                        // data:  [20000,5000,20000],
                        // Color of each bar
                        backgroundColor: this.state.boxColor,
                        // Border color of each bar
                        //borderColor: [],
                        borderWidth: 0,
                      },
                      // {
                      //   // Label for bars
                      //   label: "Fees",
                      //   // Data or value of your each variable
                      //   data: this.state.totalBill && this.state.totalBill.length>0 ? this.state.totalBill : [],
                      //   // Color of each bar
                      //   // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        // backgroundColor: 'rgba(53, 162, 235, 0.9)',
                      //   // backgroundColor: ["#ffc107"],
                      //   // Border color of each bar
                      //   //borderColor: [],
                      //   borderWidth: 0,
                      // },
                     ],
                  }}
                  // Height of graph
                  height={200}
                  options={{
                     maintainAspectRatio: true,
                     plugins:{
                        labels: {
                         render: 'value'
                       }

                    },
                     scales: {
                        yAxes: [
                           {
                              ticks: {
                                 // The y-axis value will start from zero
                                 beginAtZero: false,
                              },
                           },
                        ],
                     },
                     legend: {
                        labels: {
                           fontSize: 10,
                        },
                     },
                  }}
               />
            </div>
         </div>      
      );     
   }
}
