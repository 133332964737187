import React, { Component }     from 'react';
import $                        from 'jquery';
import jQuery                   from 'jquery';
import axios                    from 'axios';
import swal                     from 'sweetalert';
import moment                   from 'moment';
import _                        from 'underscore';
import IAssureTable             from "./ReportsTable.js";
import Select                    from 'react-select';
import 'bootstrap/js/tab.js';
import "./Reports.css";

class UserReport extends Component {     
   constructor(props) {
      super(props);
      this.state = {
         showDateWiseFilters     : true, // to show DateWise filters set true else set false
         filterObject            : {
             'dailyFilter'       : true, // to show Daily Filter set true else set false
             'weeklyFilter'      : true, // to show Weekly Filter set true else set false
             'monthlyFilter'     : true, // to show Monthly Filter set true else set false
             'yearlyFilter'      : true, // to show Yearly Filter set true else set false
             'customizedDateFilter': true // to show Customized Date Filter set true else set false
         },
         showCustomizedFilters     : false, // to add other customized filters set true else set false

         customizedFiltersArray    :                                       //Array to add different customized filters
             [
                 {
                     // 'inputType'          : "select",
                     'inputLabel'         : "Status",
                     'inputType'          : "",
                     'inputDefaultValue'  : "--Select--",
                     'inputPlaceholder'   : "",
                     'inputName'          : "status",
                     'apiUrl'             : "/api/",
                     'inputArray'         : [
                         { name: 'status', value: 'New', label: 'New' },
                         { name: 'status', value: 'Processing', label: 'Processing' },
                         { name: 'status', value: 'Ready to Dispatch', label: 'Ready to Dispatch' },
                         { name: 'status', value: 'On The Way', label: 'On The way' },
                         { name: 'status', value: 'Delivered', label: 'Delivered' },
                         { name: 'status', value: 'Cancelled', label: 'Cancelled' }
                     ],
                 }

             ],
         'currentActiveTab'  : "Daily", //If showDateWiseFilters is true then set cuttentActiveTab 'Daily' or 'Weekly' or 'Monthly' or 'Yearly' or 'Customize' or leave it ""
         'reportTitle'       : "Profile Editing Report", // Title or Heading of report
         'tableName'         : "Profile_Editing_Report", // Title or Heading of report
         'tableDatas'        : [],
         'reportData'        : {},
         'tableData'         : [],
         "startRange"        : 0,
         "limitRange"        : 10,
         "dataApiUrl"        : "/api/reports/get/profile-editing",
         "twoLevelHeader"    : {
             apply           : true,
             firstHeaderData : [
                 {
                     heading : "Sr. No",
                     mergedColoums : 1,
                     hide : true
                 },
                  {
                     heading : "Consultant ID",
                     mergedColoums : 1,
                     hide : true
                 },
                  {
                     heading : "Consultant Name",
                     mergedColoums : 1,
                     hide : true
                 },
                  {
                     heading : "Date of editing",
                     mergedColoums : 1,
                     hide : true
                 },
                 {
                     heading : "Profile Edit Trails",
                     mergedColoums : 7,
                     hide : true
                 },
             ]
         },
         "tableHeading"      : {
             srNo              : 'Sr. No',
             consultantID      : 'Consultant ID',
             consultantName      : 'Consultant Name',
             dateOfEditing     : 'Date of editing',
             profileEditTrails : 'Profile Edit Trails',
             // whetherPlanChanged: 'Whether plan changed?',
             // previousPlan      : 'Previous Plan',
             // newOrExistingPlan : 'New/existing  Plan',   // remove this and add to add this to --Enterprise Listing & Plan Report-- Ankita mam

         },
         tableObjects: {
                paginationApply        : true,
                searchApply            : true,
                excelReportExport      : false,
                searchApplyPlaceholder : "Search by Consultant name",
            },
         RecordsTable            : [],
         "startRange"            : 0,
         "limitRange"            : 10,
         "dataCount"             : 0,
         selector                : {},
         reset                   : false,
         search                  : "",
         date                    : "",
         todayDate               : '--',
         newDateOne              : '',
         weekdays                : '--',
         monthlyState            : '--',
         fromdate                : (moment(new Date()).subtract(1, 'M')).add(1, "days").format("YYYY-MM-DD"), 
         todate                  : moment(new Date()).format("YYYY-MM-DD"),
         currentYear             : moment().format('YYYY'),
         selectedOption          : null,
         searchText              : ""
      }

      this.handleChange    = this.handleChange.bind(this);
   };


   /*===========  ===========*/
   componentDidMount() {
      // Setstate values of customize filters

      if (this.state.customizedFiltersArray && this.state.customizedFiltersArray.length > 0) {
         for (var i = 0; i < this.state.customizedFiltersArray.length; i++) {
            var name = this.state.customizedFiltersArray[i].inputName;
            this.setState({
               [name] : ""
            })
         }
      }

      this.setDefaultValues();
   }

   setDefaultValues(){
      var today   = new Date();
      var dd      = today.getDate();
      var mm      = today.getMonth()+1; //January is 0!
      var yyyy    = today.getFullYear();
      
      if(dd<10){
         dd = '0'+ dd;
      }
      if(mm<10){
         mm = '0'+ mm;
      }

      var today = yyyy+'-'+mm+'-'+dd;

      this.setState({
         todayDate : today,
      },()=>{this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)});

      // var weeknumber = moment(new Date()).week() - 1;
      var weeknumber = moment(today).isoWeek();
     
      console.log("weeknumber => ",weeknumber)
      if(weeknumber<=9){
         weeknumber = "0"+weeknumber;
      }
      var yyyy    = moment(today).format("YYYY");
      var weekVal = yyyy+"-W"+weeknumber;

      console.log("yyyy => ",yyyy)
      console.log("weekVal => ",weekVal)

      this.setState({
         weekdays:weekVal,
      // },()=>{this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)});
      });

      var yyyy          = moment(today).format("YYYY");
      var monthNum      = moment(today).format("MM");
      var currentMonth  = yyyy+"-"+monthNum;
      this.setState({
         monthlyState:currentMonth,
      // },()=>{this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)});
      });

      // var fromDt = new Date();
      // var toDt = new Date(moment(fromDt).add(1,'d'));

      // var fromDt = (moment(new Date()).subtract(1, 'M')).add(1, "days").format("YYYY-MM-DD"); 
      // var toDt = moment(new Date()).format("YYYY-MM-DD");

      var fromDt  = moment(new Date()).format("YYYY-MM-DD");
      var toDt    = (moment(new Date()).add(1, 'M')).format("YYYY-MM-DD");

      this.setState({
         fromdate : fromDt,
         toDate   : toDt
      // },()=>{this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)})
      });

      var currentYear = moment().format('YYYY');

      this.setState({
         currentYear : currentYear
      // },()=>{this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)})
      });
      
      var entity = localStorage.getItem("company_Id")
      this.setState({
         entityid : 1
      })
   }

   /*===========  ===========*/
   handleChange(event) {
      const target   = event.target;
      const name     = target.name;

      this.setState({
         [name]: event.target.value
      },()=>{
         if(Object.keys(this.state.selector).length === 0){this.getData(this.state.searchText, this.state.startRange, this.state.limitRange)}else{this.getBookingData(this.state.selector,this.state.startRange, this.state.limitRange)}
      });  
   }

   setSearchText(val){
      this.setState({searchText : val});
   }
   /*===========  ===========*/
   handleChangeFilter(event){
      if (event.target.value) {
         var currentSelection =  event.target.name;
         var selector         = {};
         
         if (currentSelection === 'status') {
            selector.status = event.target.value;
         }
         if (currentSelection === 'city') {
            selector.city = event.target.value;
         }
         if (currentSelection === 'state') {
            selector.state = event.target.value;
         }
         
         this.setState({ selector: selector },()=>{
            this.getFilteredProducts(this.state.selector,this.state.startRange,this.state.limitRange);
         })        
      }
   }

   /*=========== handleDateChange() ===========*/
   handleDateChange(event) {
      const target   = event.target;
      const name     = target.name;

      this.onSelectedItemsChange("date",event.target.value)

      this.setState({
         date : event.target.value
      });   
   }

   /*=========== selectFilter() ===========*/
   selectFilter(event){
      $(".bookingfilterWrapper").toggle();
   }

   /*=========== resetFilter() ===========*/
   resetFilter(event) {
      event.preventDefault();
      this.setState({
         search            : "",
         reset             : true
      },()=>{
         this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)
      })
   }

   /*=========== nextWeek() ===========*/
   nextWeek(event){
      event.preventDefault();
      var selectedWeek = $("input#weekpicker").val();
      console.log("selectedWeek => ",selectedWeek)

      var newWeekDt     = moment(selectedWeek).add(0, 'weeks').format("YYYY-MM-DD");
      var newWeekNumber = moment(newWeekDt).week();
      //Construct the WeekNumber string as '2017-W01'
      console.log("newWeekDt => ",newWeekDt)

      console.log("newWeekNumber => ",newWeekNumber)
      if(newWeekNumber <= 9){
         newWeekNumber = '0'+newWeekNumber;
      }
      var yearNum=moment(newWeekDt).format("YYYY");
      var newWeek = yearNum + "-W" + newWeekNumber;
      this.setState({
         weekdays:newWeek,
      },()=>{
         this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)    
      });
   }

   /*=========== previousWeek() ===========*/
   previousWeek(event){
      event.preventDefault();
      var selectedWeek  = $("input#weekpicker").val();
      var newWeekDt     = moment(selectedWeek).subtract(2, 'weeks').format("YYYY-MM-DD");
      var newWeekNumber = moment(newWeekDt).week();
      
      console.log("selectedWeek => ",selectedWeek)
      console.log("newWeekDt => ",newWeekDt)
      console.log("newWeekNumber => ",newWeekNumber)

      //Construct the WeekNumber string as '2017-W01'
      if(newWeekNumber <= 9){
         newWeekNumber = '0'+newWeekNumber;
      }else if(newWeekNumber === 53){
         newWeekNumber = 52;
      }
      var yearNum = moment(newWeekDt).format("YYYY");
      console.log("yearNum => ",yearNum)
      var newWeek = yearNum + "-W" + newWeekNumber;
      console.log("newWeek => ",newWeek)
      this.setState({
         weekdays : newWeek,
      },()=>{
         this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)
      });
   }

   /*=========== nextDate() ===========*/
   nextDate(event){
      event.preventDefault();
      var selectedDate1 = $("input#todayDate").val();
      var selectedDate = selectedDate1.replace(/-/g, '\/');

      var newDate1 = new Date(selectedDate);
      var newDate2 = new Date(newDate1.getTime() + (24*60*60*1000) );
      var newDate3 = new Date(newDate2);
      var dd = newDate3.getDate();
      var mm = newDate3.getMonth()+1; //January is 0!
      var yyyy = newDate3.getFullYear();
      if(dd<10){
         dd='0'+dd;
      }
      if(mm<10){
         mm='0'+mm;
      }
      var newDate3 = yyyy+'-'+mm+'-'+dd;

      this.setState({
         todayDate : newDate3,
      },()=>{
         this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)
      });
   }

   /*=========== previousDate() ===========*/
   previousDate(event){
      event.preventDefault();
      var selectedDate1 = $("input#todayDate").val();
      var selectedDate  = selectedDate1.replace(/-/g, '\/');
      var newDate1      = new Date(selectedDate);
      var newDate2      = new Date(newDate1.getTime() - (24*60*60*1000) );
      // Session.set('newDate', newDate2);
      var newDate3      = new Date(newDate2);
      var dd            = newDate3.getDate();
      var mm            = newDate3.getMonth()+1; //January is 0!
      var yyyy          = newDate3.getFullYear();
      if(dd<10){
         dd = '0'+dd;
      }
      if(mm<10){
         mm='0'+mm;
      }
      var newDate3 = yyyy+'-'+mm+'-'+dd;
      this.setState({
         todayDate : newDate3,
      },()=>{
         this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)
      });
   }

   /*=========== nextMonth() ===========*/
   nextMonth(event){
      event.preventDefault();
      var selectedMonth    = $("input#monthlyValue").val();
      var newMonthDt       = moment(selectedMonth).add(1, 'months').format("YYYY-MM-DD");
      var newMonthNumber   = moment(newMonthDt).format("MM");

      //Construct the WeekNumber string as 'YYYY-MM'
      var yearNum    = moment(newMonthDt).format("YYYY");
      var newMonth   = yearNum+"-"+newMonthNumber;
      this.setState({
         monthlyState : newMonth,
      },()=>{
         this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)
      });
   }

   /*=========== previousMonth() ===========*/
   previousMonth(event){
      event.preventDefault();
      var selectedMonth = $("input#monthlyValue").val();

      var newMonthDt       = moment(selectedMonth).subtract(1, 'months').format("YYYY-MM-DD");
      var newMonthNumber   = moment(newMonthDt).format("MM");
      //Construct the WeekNumber string as 'YYYY-MM'
      var yearNum          = moment(newMonthDt).format("YYYY");
      var newMonth         = yearNum+"-"+newMonthNumber;
      this.setState({
         monthlyState : newMonth,
      },()=>{
         this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)
      });
   }

   /*=========== fromdates() ===========*/
   fromdates(event){
      var selectedDate1 = $("input#fromdate").val();   
      var dd            = new Date(selectedDate1).getDate();
      var mm            = new Date(selectedDate1).getMonth()+1; //January is 0!
      var yyyy          = new Date(selectedDate1).getFullYear();
    
      if(dd<10){
         dd='0'+dd;
      }
      if(mm<10){
         mm='0'+mm;
      }
      var Fromdate = yyyy+'-'+mm+'-'+dd;
      this.setState({
         fromdate : Fromdate,
      },()=>{
         this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)
      });
   }

   /*=========== todates() ===========*/
   todates(event){
      var selectedDate2 = $("input#todate").val();
      var dd            = new Date(selectedDate2).getDate();
      var mm            = new Date(selectedDate2).getMonth() + 1; //January is 0!
      var yyyy          = new Date(selectedDate2).getFullYear();
      
      if(dd<10){
         dd='0'+dd;
      }
      if(mm<10){
         mm='0'+mm;
      }

      var Todate        = yyyy+'-'+mm+'-'+dd;
      var dateCompare   =  moment(Todate).isAfter(this.state.fromdate);
      
      if(dateCompare === true){
         this.setState({
            todate:Todate,
         },()=>{     
            this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)
         });
      }else{
         swal('From date should not be less than To date')
         this.setState({
            todate:this.state.fromdate
         },()=>{
            this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)
         })
      }
   }

   /*=========== nextYear() ===========*/
   nextYear(event){
      event.preventDefault();
      var currentYear   = this.state.currentYear;
      var newYear       = moment(currentYear).add(1,'years').format('YYYY');
      
      this.setState({currentYear: newYear},()=>{
         this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)
      })
   }

   /*=========== previousYear() ===========*/
   previousYear(event){
      event.preventDefault();
      var currentYear   = this.state.currentYear;
      var newYear       = moment(currentYear).subtract(1,'years').format('YYYY');
      
      this.setState({currentYear: newYear},()=>{
         this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)
      })
   }

   /*=========== changeTab() ===========*/
   changeTab(value,event){
      this.setState({currentActiveTab:value},()=>{
         this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)
      })
   }

   /*=========== getFilteredProducts() ===========*/
   getFilteredProducts(){}

   /*=========== getData() ===========*/
   getData(search, startRange, limitRange){ 

      console.log("searchText => ",search);

      this.setState({reset:false})

      var formValues ={
         startDate   : "",
         endDate     : "",
         searchText  : search,
         startRange  : startRange,
         limitRange  : limitRange
      }
      if (this.state.customizedFiltersArray && this.state.customizedFiltersArray.length > 0) {
         for (var i = 0; i < this.state.customizedFiltersArray.length; i++) {
            var name = this.state.customizedFiltersArray[i].inputName;
            console.log(i," name => ",name);
            formValues[name] = this.state[name] && this.state[name] !== "" ? this.state[name].value : this.state[name];
         }
      }
      var currentActiveTab = this.state.currentActiveTab;
        
      if(currentActiveTab === "Daily"){
         var todayDateSelected   = this.state.todayDate;
         var startDate           = moment(todayDateSelected).startOf('day'); // set to 12:00 am today
         var endDate             = moment(todayDateSelected).endOf('day'); // set to 23:59 pm today
         formValues.startDate    = new Date(startDate);
         formValues.endDate      = new Date(endDate);

      }else if(currentActiveTab === "Weekly"){
         var weekData            = this.state.weekdays;
         var mondayInWeek        = moment(weekData).day("Monday").week(weekData).format();
         var mondayInWeekDt      = new Date(mondayInWeek);
         var sundayOfWeek        = moment(mondayInWeek).add(7,"days").format();
         var sundayOfWeekDt      = new Date(sundayOfWeek);
         formValues.startDate    = mondayInWeekDt;
         formValues.endDate      = sundayOfWeekDt;

      }else if(currentActiveTab === "Monthly"){
         var selectedMonth       = this.state.monthlyState;
         var monthDateStart      = new Date(moment(selectedMonth).month("YYYY-MM"));//Find out first day of month with selectedMonth
         var monthDateEnd        = new Date(moment(selectedMonth).add(1,"M"));
         formValues.startDate    = monthDateStart;
         formValues.endDate      = monthDateEnd;

      }else if(currentActiveTab === "Yearly"){
         var selectedYear        = this.state.currentYear;
         var yearDateStart       = new Date("1/1/" + selectedYear);
         var yearDateEnd         = new Date (yearDateStart.getFullYear(), 11, 31);
         formValues.startDate    = yearDateStart;
         formValues.endDate      = yearDateEnd;

      }else if(currentActiveTab === "Custom"){
         var fromDate            = this.state.fromdate;
         var todate              = this.state.todate;
         formValues.startDate    = new Date(fromDate);
         formValues.endDate      = new Date(todate);
      }

      var status = this.state.status;
      console.log("formValues",formValues)
        
      axios.post(this.state.dataApiUrl,formValues)
         .then((response) => {
            console.log("response=====================",response)
            this.setState({
               RecordsTable   : response.data.data,
               tableData      : response.data.data,
               dataCount      : response.data.dataCount
            })
         })
         .catch((error) =>{
            console.log("ERROR : ", error); 
         })
   }

   handleChangeFilters(event){      
      var name    = event.name;
      var value   = event.value;

      if (this.state.customizedFiltersArray && this.state.customizedFiltersArray.length) {
         var customFilter = this.state.customizedFiltersArray.filter(filter => filter.inputName === name);
         if (customFilter && customFilter.length > 0) {
            if (customFilter[0].resetValueOf !== undefined && customFilter[0].resetValueOf !== "" && customFilter[0].resetValueOf.length > 0) {
               for(var j=0; j<customFilter[0].resetValueOf.length; j++){
                  this.setState({
                     [customFilter[0].resetValueOf[j]] : null
                  })
               }                 
            }
         }
      }
      this.setState({ 
         [name]  : event
      },()=>{
         if (this.state.customizedFiltersArray && this.state.customizedFiltersArray.length) {
            for(var i=0; i<this.state.customizedFiltersArray.length; i++){
               if (this.state.customizedFiltersArray[i].inputName === name && this.state.customizedFiltersArray[i].onChangeMethod) {
                  this.state.customizedFiltersArray[i].onChangeMethod(this.state[name].value);
               }
            }
         }
         this.getData(this.state.searchText, this.state.startRange,this.state.limitRange)
         console.log(`Option selected:`, name, " => ",this.state[name]);
      });
   };

   /*======== showMoreFilters() ========*/
    showMoreFilters(event){
        event.preventDefault();
        var filterBtn = event.target;
        var element   = document.getElementById(this.state.tableName + "-" + this.state.currentActiveTab + "-Filters");
        
        if (element) {
            $("#" + this.state.tableName + "-" + this.state.currentActiveTab + "-Filters").toggle();
        }
        if ((element.style.display) === "none") {
            filterBtn.innerHTML   = "<i class='fa fa-sliders' aria-hidden='true'></i> More Filters";
        } else {
            filterBtn.innerHTML   = "<i class='fa fa-sliders' aria-hidden='true'></i> Hide Filters";
        }
    }
   getdifference(result1, result2,parameter ){

      var result = result1.filter(function(o1){
          // filter out (!) items in result2
          return !result2.some(function(o2){
               console.log(o1.category === o2.category,o1.category ,o2.category)
              return o1.category != o2.category;          // assumes unique id
          });
      })

      console.log("result",result)
   }

   /*=========== render() ===========*/
   render() {
      return (
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding">
            <section className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding content">   
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pageContent">
                     <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 NOPadding topMargin box-header with-border">
                           <h4 className="col-lg-12 col-md-12 col-xs-12 col-sm-12 weighttitle NOPadding">{this.state.reportTitle}</h4>
                        </div>
                     </div>
                     <section className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding">
                           <div className="reportWrapper col-lg-12 NOPadding"> 
                              {/*{ this.state.showDateWiseFilters
                           ? */}                            
                                 <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 dateTabsWrapper">
                                       <div className="col-lg-12 nav-center col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding">
                                          <ul className="nav nav-pills navTabList">
                                             {this.state.filterObject && this.state.filterObject !== "undefined" && this.state.filterObject !== null && this.state.filterObject.dailyFilter 
                                                ?                                                       
                                                   <li className="navListItem">
                                                       <a href="#Daily" data-toggle="tab" className={"navTab " + (this.state.currentActiveTab === "Daily" ? "active" : "")} value="Daily" onClick={this.changeTab.bind(this,'Daily')}>
                                                            Daily
                                                       </a>
                                                   </li>
                                               :
                                                null
                                             }
                                             { this.state.filterObject && this.state.filterObject !== "undefined" && this.state.filterObject.weeklyFilter 
                                                ?
                                                   <li className="navListItem">
                                                       <a href="#Weekly" data-toggle="tab" className={"navTab " + (this.state.currentActiveTab === "Weekly" ? "active" : "")} value="Weekly" onClick={this.changeTab.bind(this,'Weekly')}>
                                                            Weekly
                                                       </a>
                                                   </li>
                                                :
                                                   null
                                             }
                                             { this.state.filterObject && this.state.filterObject !== "undefined" && this.state.filterObject.monthlyFilter 
                                                ?
                                                   <li className="navListItem">
                                                       <a href="#Monthly" data-toggle="tab" className={"navTab " + (this.state.currentActiveTab === "Monthly" ? "active" : "")} value="Monthly" onClick={this.changeTab.bind(this,'Monthly')} >
                                                            Monthly
                                                       </a>
                                                   </li>
                                                :
                                                   null
                                             }
                                             {this.state.filterObject && this.state.filterObject !== "undefined" && this.state.filterObject.yearlyFilter 
                                                ?
                                                   <li className="navListItem">
                                                       <a href="#Yearly" data-toggle="tab" className={"navTab " + (this.state.currentActiveTab === "Yearly" ? "active" : "")} value="Yearly" onClick={this.changeTab.bind(this,'Yearly')}>
                                                            Yearly
                                                       </a>
                                                   </li>
                                               :
                                                   null
                                             }
                                             {this.state.filterObject && this.state.filterObject !== "undefined" && this.state.filterObject.customizedDateFilter 
                                                ? 
                                                    <li className="navListItem">
                                                       <a href="#Custom" data-toggle="tab" className={"navTab " + (this.state.currentActiveTab === "Custom" ? "active" : "")} value="Custom" onClick={this.changeTab.bind(this,'Custom')}>
                                                            Date Range
                                                       </a>
                                                   </li>
                                               :
                                                null
                                             }                          
                                             </ul>
                                       </div>
                                       <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
                                             <div className={"tab-pane " + (this.state.currentActiveTab === "Daily" ? "active" : "")} id="Daily">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 marginStyle NOPadding">
                                                   <div className="col-lg-6 col-lg-offset-3 col-md-6  col-lg-offset-3 col-sm-12 col-xs-12 NOPadding">
                                                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 reports-select-date-Title">Daily {this.state.reportTitle}</div>
                                                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 custom-date input-group">
                                                         <span className="commonReportArrowPoiner input-group-addon" id="previousDate" onClick={this.previousDate.bind(this)} ><i className="fa fa-chevron-circle-left" id="fromdate-arrow-left" aria-hidden="true"></i></span>
                                                         <input 
                                                            name     = "todayDate" 
                                                            id       = "todayDate" 
                                                            ref      = "todayDate"  
                                                            type     = "date" 
                                                            className   = "textAlignCenter boxHeight36 form-control" 
                                                            onChange    = {this.handleChange.bind(this)} 
                                                            value       = {this.state.todayDate}
                                                         /> 
                                                         <span className="commonReportArrowPoiner input-group-addon" 
                                                               id="nextDate" onClick={this.nextDate.bind(this)} >
                                                            <i className="fa fa-chevron-circle-right" id="fromdate-arrow-right" aria-hidden="true"></i>
                                                         </span>
                                                      </div>
                                                </div>
                                                </div>
                                             </div>
                                             <div className={"tab-pane " + (this.state.currentActiveTab === "Weekly" ? "active" : "")} id="Weekly">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 marginStyle NOPadding">
                                                   <div className="col-lg-6 col-lg-offset-3 col-md-6  col-lg-offset-3 col-sm-12 col-xs-12 NOPadding">
                                                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 reports-select-date-Title">Weekly {this.state.reportTitle}</div>
                                                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 custom-date input-group">
                                                         <span className="commonReportArrowPoiner input-group-addon" id="previousDate" onClick={this.previousWeek.bind(this)} ><i className="fa fa-chevron-circle-left" id="fromdate-arrow-left" aria-hidden="true"></i></span>
                                                         <input  
                                                            name     = "weekdays" 
                                                            id          = "weekpicker" 
                                                            ref      = "weekdays" 
                                                            type     = "week" 
                                                            className   = "textAlignCenter boxHeight36 form-control" 
                                                            value       = {this.state.weekdays}  
                                                            onChange    = {this.handleChange.bind(this)}
                                                         />
                                                         <span className="commonReportArrowPoiner input-group-addon" id="nextDate"  onClick={this.nextWeek.bind(this)} ><i className="fa fa-chevron-circle-right"  id="fromdate-arrow-right" aria-hidden="true"></i></span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          <div className={"tab-pane " + (this.state.currentActiveTab === "Monthly" ? "active" : "")} id="Monthly">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 marginStyle NOPadding">
                                                   <div className="col-lg-6 col-lg-offset-3 col-md-6  col-lg-offset-3 col-sm-12 col-xs-12 NOPadding">
                                                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 reports-select-date-Title">Monthly {this.state.reportTitle}</div>
                                                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 custom-date input-group">
                                                   <span className="commonReportArrowPoiner input-group-addon" id="previousMonth" onClick={this.previousMonth.bind(this)} ><i className="fa fa-chevron-circle-left" id="fromdate-arrow-left" aria-hidden="true"></i></span>
                                                         <input 
                                                            name     = "monthlyValue" 
                                                            id          = "monthlyValue" 
                                                            ref      = "monthlyValue"  
                                                            type     = "month" 
                                                            className   = "textAlignCenter boxHeight36 form-control"
                                                            value       = {this.state.monthlyState} 
                                                            onChange    = {this.handleChange.bind(this)}  
                                                         />
                                                         <span className="commonReportArrowPoiner input-group-addon" id="nextMonth" onClick={this.nextMonth.bind(this)}><i className="fa fa-chevron-circle-right" id="fromdate-arrow-right" aria-hidden="true"></i></span>

                                                   {/*<span className="input-group-addon custom-date-icon-left" id="previousMonth" onClick={this.previousMonth.bind(this)}><img src="/billingManagement/left-arrow.png" id="fromdate-arrow-left"/></span>*/}
                                                            {/*<input className="form-control" type="month" name="monthlyValue" id="monthlyValue" onChange={this.handleChange.bind(this)} value={this.state.monthlyState}/>*/}
                                                            {/*<span className="input-group-addon custom-date-icon-right" id="nextMonth" onClick={this.nextMonth.bind(this)}><img src="/billingManagement/right-arrow.png" id="fromdate-arrow-right"/></span>*/}                            
                                                      </div>
                                                </div>
                                                </div>
                                             </div>
                                             <div className={"tab-pane " + (this.state.currentActiveTab === "Yearly" ? "active" : "")} id="Yearly">
                                             <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 marginStyle NOPadding">
                                                   <div className="col-lg-6 col-lg-offset-3 col-md-6  col-lg-offset-3 col-sm-12 col-xs-12 NOPadding">
                                                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 reports-select-date-Title">Yearly {this.state.reportTitle}</div>
                                                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 custom-date input-group">
                                                   <span className="commonReportArrowPoiner input-group-addon" id="previousYear" onClick={this.previousYear.bind(this)}><i className="fa fa-chevron-circle-left" id="fromdate-arrow-left" aria-hidden="true"></i></span>
                                                         <input 
                                                            name     = "currentYear" 
                                                            id          = "currentYear" 
                                                            ref      = "currentYear"  
                                                            type     = "text" 
                                                            className   = "textAlignCenter boxHeight36 form-control" 
                                                            onChange    = {this.handleChange.bind(this)} 
                                                            value       = {this.state.currentYear} 
                                                         />
                                                         <span className="commonReportArrowPoiner input-group-addon" id="nextYear" onClick={this.nextYear.bind(this)}><i className="fa fa-chevron-circle-right" id="fromdate-arrow-right" aria-hidden="true"></i></span>

                                                   {/*<span className="input-group-addon custom-date-icon-left" id="previousYear" onClick={this.previousYear.bind(this)}><img src="/billingManagement/left-arrow.png" id="fromdate-arrow-left"/></span>*/}
                                                            {/*<input className="textAlignCenter boxHeight36 form-control" type="text" name="currentYear" id="currentYear" onChange={this.handleChange.bind(this)} value={this.state.currentYear}/>
                                                            <span className="input-group-addon custom-date-icon-right" id="nextYear" onClick={this.nextYear.bind(this)}><img src="/billingManagement/right-arrow.png" id="fromdate-arrow-right"/></span>*/}                            
                                                      </div>
                                                </div>
                                                </div>
                                             </div>
                                             <div className={"tab-pane " + (this.state.currentActiveTab === "Custom" ? "active" : "")} id="Custom">
                                             <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 marginStyle NOPadding">
                                                   <div className="col-lg-6 col-lg-offset-3 col-md-6  col-lg-offset-3 col-sm-12 col-xs-12 NOPadding">
                                                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 reports-select-date-Title">{this.state.reportTitle}</div>
                                                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 custom-date input-group">
                                                   <label className="col-lg-6 col-md-6 col-sm-12 col-xs-12 boxBorder">From
                                                            <input 
                                                               name        = "fromDate" 
                                                               id          = "fromdate" 
                                                               type        = "date" 
                                                               className   = "textAlignCenter boxHeight36 form-control" 
                                                               onChange    = {this.fromdates.bind(this)}
                                                               value       = {this.state.fromdate} 
                                                            />
                                                         </label>
                                                         <label className="col-lg-6 col-md-6 col-sm-12 col-xs-12 boxBorder">To
                                                            <input 
                                                               name        = "toDate" 
                                                               id          = "todate" 
                                                               type        = "date" 
                                                               className   = "textAlignCenter boxHeight36 form-control" 
                                                               onChange    = {this.todates.bind(this)}
                                                               value       = {this.state.todate} 
                                                            />
                                                         </label>
                                                      </div>
                                                   </div>
                                             </div>
                                             </div>
                                       </div>
                                    </div>


                                 {this.state.showCustomizedFilters
                                    ?
                                       <>
                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding marginTop17">
                                             <button className="btn button3" id="btnCheck" 
                                               onClick={this.showMoreFilters.bind(this)} >
                                               <i className="fa fa-sliders"></i> More Filters 
                                             </button>
                                          </div>

                                          <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 filtersDiv" id={this.state.tableName + "-" + this.state.currentActiveTab + "-Filters"}>   
                                             {this.state.customizedFiltersArray && this.state.customizedFiltersArray.length > 0
                                                ?
                                                   this.state.customizedFiltersArray.map((filtersdata, i) => {
                                                      if(filtersdata.inputType !== ""){
                                                      
                                                      return (
                                                         <div key={i} className="form-margin col-lg-3 col-md-6 col-sm-12 col-xs-12" >
                                                            <label className="labelform col-lg-12 col-md-12 col-sm-12 col-xs-12">{filtersdata.inputLabel}</label>
                                                            {/*{console.log("filtersdata.inputName => ",filtersdata.inputName)}*/}
                                                            {filtersdata.inputType === "select"
                                                            ?
                                                               <Select
                                                                  value       = {this.state[filtersdata.inputName]}
                                                                  name        = {filtersdata.inputName}
                                                                  onChange    = {this.handleChangeFilters.bind(this)}
                                                                  options     = {filtersdata.inputArray}
                                                               />
                                                           :
                                                               null
                                                           }
                                                         </div>                                                               
                                                      );
                                                      }
                                                  })
                                             :
                                                null
                                          }
                                          </div>
                                       </>
                                    :
                                       null
                                 }
                                 <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding">
                                           
                                    <div className="report-list-downloadMain col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                      <div className="table-responsive commonHeaderFixTable mt" id="section-to-screen">
                                        <table className="table iAssureITtable-bordered fixedTable" id="FamilyCoverageReport">
                                          <thead className="tempTableHeader fixedHeader">
                                            <tr className="tempTableHeader"></tr>
                                            <tr className="">
                                              <th className="umDynamicHeader srpadd text-center">
                                                <div className="colSr">Sr.No.</div>
                                              </th>
                                              <th  className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col2">Consultant Name</div>
                                                <span   className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th id="district" className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col3">Date of Editing</div>
                                                <span  id="district" className="fa fa-sort tableSort"></span>
                                              </th>
                                            {/*  <th id="name_family" className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col1">Consultant ID</div>
                                                <span  id="name_family" className="fa fa-sort tableSort"></span>
                                              </th>*/}
                                              <th  className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col2">First Name</div>
                                                <span   className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th  className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col2">Last Name</div>
                                                <span   className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th id="block" className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col4">Mobile Number</div>
                                                <span  className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col5">Email</div>
                                                <span  className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col6">Location</div>
                                                <span  className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col7">Call Fees</div>
                                                <span  className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col8">PAN</div>
                                                <span  className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col9">Qualification</div>
                                                <span  className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col10">Experience</div>
                                                <span  className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col100">Languages</div>
                                                <span  className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col11">About Yourself</div>
                                                <span  className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col11">Work Experience</div>
                                                <span  className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col12">Recognitions</div>
                                                <span  className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col13">Memberships</div>
                                                <span  className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col14">Categories</div>
                                                <span  className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col15">Sub Categories</div>
                                                <span  className="fa fa-sort tableSort"></span>
                                              </th>
                                              <th className="umDynamicHeader srpadd textAlignLeft ">
                                                <div className="wrapWord col16">Expertises</div>
                                                <span  className="fa fa-sort tableSort"></span>
                                              </th>
                                            </tr>
                                          </thead>                              
                                          <tbody className={this.state.tableData && this.state.tableData.length > 0 ? "scrollContent" : ""} >
                                          { 
                                             this.state.tableData 
                                             ?
                                                this.state.tableData.length > 0
                                                ?
                                                   this.state.tableData.map((value, i)=> {
                                                   var length = value?.updateLog?.length
                                                   value.updateLog.sort(function(a,b){
                                                     // Turn your strings into dates, and then subtract them
                                                     // to get a value that is either negative, positive, or zero.
                                                     {return new Date(b?.updatedAt) - new Date(a.updatedAt);}
                                                   });
                                                   return(                                    
                                                       <React.Fragment key={i}>
                                                         { 
                                                           length !== 0 && value.updateLog ?
                                                               Object.entries(value.updateLog).map(([key,data], index)=> {
                                                                     var beforeSub = data.beforeChanges?.catg_subCatg_expertise && data.beforeChanges?.catg_subCatg_expertise.length>0 ? data.beforeChanges?.catg_subCatg_expertise?.flatMap(s => s?.subCategory) : []
                                                                     var updateSub = data.updateChanges?.catg_subCatg_expertise && data.updateChanges?.catg_subCatg_expertise.length>0 ? data.updateChanges?.catg_subCatg_expertise?.flatMap(s => s?.subCategory) : []
                                                                     let subCategoryResult   = beforeSub.length > updateSub.length ?  beforeSub.filter(o1 => !updateSub.some(o2 => o1?.businessSubCategory === o2?.businessSubCategory)) : updateSub.filter(o1 => !beforeSub.some(o2 => o1?.businessSubCategory === o2?.businessSubCategory));
                                                                     var beforeSubcategories = beforeSub.map((a)=> a?.businessSubCategory ? a?.businessSubCategory :null).filter(b => b!=null)
                                                                     var updateSubcategories = updateSub.map((a)=> a?.businessSubCategory ? a?.businessSubCategory :null).filter(b => b!=null)
                                                                     
                                                                     var beforeExp = data.beforeChanges?.catg_subCatg_expertise && data.beforeChanges?.catg_subCatg_expertise.length>0 ? data.beforeChanges?.catg_subCatg_expertise?.flatMap(s => s?.expertise) : []
                                                                     var updateExp = data.updateChanges?.catg_subCatg_expertise && data.updateChanges?.catg_subCatg_expertise.length>0 ? data.updateChanges?.catg_subCatg_expertise?.flatMap(s => s?.expertise) : []
                                                                     let expertiseResult = beforeExp.filter(o1 => !updateExp.some(o2 => (o1?.expertise ?o1?.expertise: "") === (o2?.expertise ? o2?.expertise: "")));
                                                                     var beforeExpertises = beforeExp.map((a)=> a?.expertise ? a?.expertise :null).filter(b => b!=null)
                                                                     var updateExpertises = updateExp.map((a)=> a?.expertise ? a?.expertise :null).filter(b => b!=null)
                                                                     
                                                                     let categoryResult = data.updateChanges?.catg_subCatg_expertise && data.updateChanges?.catg_subCatg_expertise.length>0 ? data.updateChanges?.catg_subCatg_expertise.filter(o1 => !data.beforeChanges?.catg_subCatg_expertise.some(o2 => o1.category === o2.category)):[];
                                                                     let languageResult = data.updateChanges?.otherInfo?.languages.filter(o1 => !data.beforeChanges?.otherInfo?.languages.some(o2 => (o1.language ? o1.language : "") === (o2.language?o2.language:"")));
                                                                     {/*{{console.log("index",index)}}*/}
                                                                     {/*{{console.log("categoryResult",categoryResult)}}*/}
                                                                     {/*{{console.log("subCategoryResult",subCategoryResult)}}*/}
                                                                     {/*{console.log("beforeSub",beforeSub)}*/}
                                                                     {/*{console.log("updateSub",updateSub)}*/}
                                                                     {/*{{console.log("updateSubcategories",updateSubcategories)}}*/}
                                                                     {/*{console.log("beforeExpertises",beforeExpertises)}*/}
                                                                     {/*{console.log("updateExpertises",updateExpertises)}*/}
                                                                     {/*{console.log("expertiseResult",expertiseResult)}*/}
                                                                     {/*{console.log("value",i,"**",value)}*/}
                                                                     {/*{console.log("length",length)}*/}
                                                                  return(   
                                                                     <tr className="tableRow" key={index}>  
                                                                        {
                                                                           index === 0 
                                                                           ?
                                                                              <React.Fragment>
                                                                                 <td rowSpan={length} className="textAlignCenter"> <div className="colSr">{value.srNo}</div></td>
                                                                                 <td rowSpan={length} className=""><div className=" noWrapText">{value.consultantName ?value.consultantName: "-" }</div></td>
                                                                                                   
                                                                              </React.Fragment>
                                                                           :
                                                                             null
                                                                        }
                                                                        <td  className=""><div className="noWrapText col2">{data.updatedAt !== "-" ?moment(data.updatedAt).format('DD-MM-YYYY HH:mm'): "-" }</div></td>
                                                                        <td  className=""><div className={(data.beforeChanges.profile.firstname != data.updateChanges.profile.firstname )?"bold col2": "col2"}>{data.updateChanges.profile.firstname ? data.updateChanges.profile.firstname : ""}</div></td>
                                                                        <td  className=""><div className={(data.beforeChanges.profile.lastname != data.updateChanges.profile.lastname )?"bold col2": "col2"}>{data.updateChanges.profile.lastname ? data.updateChanges.profile.lastname : ""}</div></td>
                                                                        <td  className=""><div className={(data.beforeChanges.profile.mobile != data.updateChanges.profile.mobile )?"bold col2": "col2"}>{data.updateChanges.profile.mobile ? data.updateChanges.profile.mobile : ""}</div></td>
                                                                        <td  className=""><div className={(data.beforeChanges?.profile?.email != data.updateChanges?.profile?.email )?"bold col2": "col2"}>{data.updateChanges.profile.email ? data.updateChanges.profile.email : ""}</div></td>
                                                                        <td  className=""><div className={(data.beforeChanges?.address?.city != data.updateChanges?.address?.city )?"bold col2": "col2"}>{data.updateChanges.address.city ? data.updateChanges.address.city : ""}</div></td>
                                                                        <td  className=""><div className={(data.beforeChanges?.otherInfo?.fees != data.updateChanges?.otherInfo?.fees )?"bold col2": "col2"}>{data.updateChanges.otherInfo.fees ? data.updateChanges.otherInfo.fees : ""}</div></td>

                                                                        <td  className=""><div className={(data.beforeChanges?.otherInfo?.panNumber != data.updateChanges?.otherInfo?.panNumber )?"bold pan": "pan"}>{data.updateChanges.otherInfo.panNumber ? data.updateChanges.otherInfo.panNumber : ""}</div></td>
                                                                        <td  className=""><div className={(data.beforeChanges?.otherInfo?.qualification != data.updateChanges?.otherInfo?.qualification )?"bold col2": "col2"}>{data.updateChanges.otherInfo.qualification ? data.updateChanges.otherInfo.qualification : ""}</div></td>
                                                                        <td  className=""><div className={(data.beforeChanges?.otherInfo?.experience != data.updateChanges?.otherInfo?.experience )?"bold col2": "col2"}>{data.updateChanges.otherInfo.experience ? data.updateChanges.otherInfo.experience : ""}</div></td>
                                                                        <td  className=""><div className={languageResult.length> 0 ? "bold col2":"col2"}>{data.updateChanges.otherInfo.languages && data.updateChanges.otherInfo.languages.length>0 ? data.updateChanges.otherInfo.languages.map((a)=>{ return a.language + ", "}) : ""}</div></td>
                                                                        <td  className=""><div className={(data.beforeChanges?.otherInfo?.aboutMyself != data.updateChanges?.otherInfo?.aboutMyself )?"bold col2": "col2"}>{data.updateChanges.otherInfo.aboutMyself ? data.updateChanges.otherInfo.aboutMyself : ""}</div></td>
                                                                        <td  className=""><div className={(data.beforeChanges?.otherInfo?.pastWorkExp != data.updateChanges?.otherInfo?.pastWorkExp )?"bold col2": "col2"}>{data.updateChanges.otherInfo.pastWorkExp!=null ? data.updateChanges.otherInfo.pastWorkExp : ""}</div></td>
                                                                        <td  className=""><div className={(data.beforeChanges?.otherInfo?.awards != data.updateChanges?.otherInfo?.awards )?"bold col2": "col2"}>{data.updateChanges.otherInfo.awards ? data.updateChanges.otherInfo.awards : ""}</div></td>
                                                                        <td  className=""><div className={(data.beforeChanges?.otherInfo?.membership != data.updateChanges?.otherInfo?.membership )?"bold col2": "col2"}>{data.updateChanges.otherInfo.membership ? data.updateChanges.otherInfo.membership : ""}</div></td>
                                                                        <td  className=""><div className={categoryResult.length > 0 ? "bold" : ""}>{data.updateChanges?.catg_subCatg_expertise?.length>0 ? data.updateChanges?.catg_subCatg_expertise?.map(s => s?.category)?.join(", ") : "-"}</div></td>
                                                                        <td  className=""><div className={subCategoryResult.length > 0 ? "bold" : ""}>{updateSubcategories.length>0? updateSubcategories.map((a)=> " "+a+","): "-" }</div></td>
                                                                        <td  className=""><div className={expertiseResult.length > 0 ? "bold" : ""}>{updateExpertises.length>0? updateExpertises.map((a)=> " "+a+","): "-" }</div></td>
                                                                     </tr>

                                                                  )

                                                               })
                                                            : null
                                                         }
                                                         </React.Fragment>
                                                      )
                                                   })
                                                :
                                                  <tr className="trAdmin"><td colSpan= "21" className="noTempData textAlignCenter">No Record Found!</td></tr>                   
                                              :
                                                <tr className="trAdmin">
                                                  <td colSpan="22" className="noTempData textAlignCenter">
                                                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                                                    <span className="sr-only">Loading...</span>                 
                                                  </td>
                                                </tr>    
                                            } 
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                 </div>
                           </div>
                        </div>
                     </section>
                  </div>
            </section>
            </div>
      );
   }
}

export default UserReport;

                                                    {/* {
                                                            index === 0 
                                                            ?
                                                              <React.Fragment>     

                                                                        <td  className=""><div className=" col2">aaa{data.dateOfEditing !== "-" ?moment(data.dateOfEditing).format('DD-MM-YYYY'): "-" }</div></td>
                                                                        <td  className=""><div className=" col2">{data.beforeChanges.profile.firstname ? data.beforeChanges.profile.firstname : ""}</div></td>
                                                                        <td  className=""><div className=" col2">{data.beforeChanges.profile.lastname ? data.beforeChanges.profile.lastname : ""}</div></td>
                                                                        <td  className=""><div className=" col2">{data.beforeChanges.profile.mobile ? data.beforeChanges.profile.mobile : ""}</div></td>
                                                                        <td  className=""><div className=" col2">{data.beforeChanges.profile.email ? data.beforeChanges.profile.email : ""}</div></td>
                                                                        <td  className=""><div className=" col2">{data.beforeChanges.address.city ? data.beforeChanges.address.city : ""}</div></td>
                                                                        <td  className=""><div className=" col2">{data.beforeChanges.otherInfo.fees ? data.beforeChanges.otherInfo.fees : ""}</div></td>

                                                                        <td  className=""><div className="pan col2">{data.beforeChanges.otherInfo.panNumber ? data.beforeChanges.otherInfo.panNumber : ""}</div></td>
                                                                        <td  className=""><div className=" col2">{data.beforeChanges.otherInfo.qualification ? data.beforeChanges.otherInfo.qualification : ""}</div></td>
                                                                        <td  className=""><div className=" col2">{data.beforeChanges.otherInfo.experience ? data.beforeChanges.otherInfo.experience : ""}</div></td>
                                                                        <td  className=""><div className=" ">{data.beforeChanges.otherInfo.languages && data.beforeChanges.otherInfo.languages.length>0 ? data.beforeChanges.otherInfo.languages.map((a)=>{ return a.language + ", "}) : ""}</div></td>
                                                                        <td  className=""><div className=" col2">{data.beforeChanges.otherInfo.aboutMyself ? data.beforeChanges.otherInfo.aboutMyself : ""}</div></td>
                                                                        <td  className=""><div className=" col2">{data.beforeChanges.otherInfo.pastWorkExp!=null ? data.beforeChanges.otherInfo.pastWorkExp : ""}</div></td>
                                                                        <td  className=""><div className=" col2">{data.beforeChanges.otherInfo.awards!=null ? data.beforeChanges.otherInfo.awards : ""}</div></td>
                                                                        <td  className=""><div className=" col2">{data.beforeChanges.otherInfo.membership!=null ? data.beforeChanges.otherInfo.membership : "-"}</div></td>
                                                                        <td  className=""><div className=" col2">{data.beforeChanges?.catg_subCatg_expertise?.length>0 ? data.beforeChanges?.catg_subCatg_expertise?.map(s => s?.category)?.join(", ") : "-"}</div></td>
                                                               </React.Fragment>
                                                      :
                                                        null
                                                    }                 */}
