import React, { Component } from 'react';
import { connect }          from 'react-redux';

import $                    from 'jquery';
import jQuery               from 'jquery';
import axios                from 'axios';
import swal                 from 'sweetalert';
import withRouter           from '../common/withRouter.js';
import _                    from 'underscore';
import moment               from 'moment-timezone';
import PieChart             from './chart1/PieChart.js'
import BarChart             from './chart1/BarChart.js'
import TableComponent             from './TableComponent/TableComponent.js'
import StatusComponent             from './StatusComponent/StatusComponent.js'
import './Dashboard.css';
import 'bootstrap/js/tab.js';

var apiLink = "";
class Dashboard extends React.Component{
   constructor(props) {
      super(props);
      this.state = {
         "user_ID"   : "",
         "startRange": 0,
         "limitRange": 10,
         "editId"    :  '',
         "fieldValue" : "",
         // fromDate    : "2022-01-01",
         fromDate    : moment().startOf('month').format("YYYY-MM-DD"),
         toDate      : moment().endOf('month').format("YYYY-MM-DD")
      };
   }
   componentDidMount(){
      var userDetails = (localStorage.getItem('userDetails'));
      var userData = JSON.parse(userDetails);
      var user_id = userData.user_id
      console.log("summarydata => ",user_id);
      this.setState({
         // fromDate    : "2022-01-01",
         fromDate    : moment().startOf('month').format("YYYY-MM-DD"),
         toDate      : moment().endOf('month').format("YYYY-MM-DD")
      },()=>{
         this.getData(this.state.fromDate,this.state.toDate)
         this.getApprovalData()
      })

   }

   getData(fromDate,toDate){
      if(fromDate && toDate){
         var formValues = {
            fromDate    : fromDate,
            toDate      : toDate
         }
        
         axios.post('/api/dashboard/post/callsDataForAdmin',formValues)
            .then(callsData => {  
               // console.log("callsData",callsData.data.totals)
               this.setState({
                  totalCompletedCalls         : callsData.data.totals.totalCompletedCalls ? callsData.data.totals.totalCompletedCalls : 0,
                  totalBill                   : callsData.data.totals.totalBill ? callsData.data.totals.totalBill : 0,
                  totalCancelledCalls         : callsData.data.totals.totalCancelledCalls ? callsData.data.totals.totalCancelledCalls : 0,
                  totalFutureCalls            : callsData.data.totals.totalFutureCalls ? callsData.data.totals.totalFutureCalls : 0,
               })
            })
            .catch(error=>{
                console.log("error => ",error);
            })
         axios.post('/api/dashboard/post/userDataForAdmin',formValues)
            .then(userData => {            
                  var consultantsPlanwise = userData.data.totals.consultantsPlanwise;
                  for (var i = 0; i < consultantsPlanwise.length; i++) {
                     if(consultantsPlanwise[i].planName =='Standard'){
                        var standardConsultants = consultantsPlanwise[i].totalConsultants
                        var standardCollections = consultantsPlanwise[i].planCost
                     }else if(consultantsPlanwise[i].planName =='Premium'){
                        var premiumConsultants = consultantsPlanwise[i].totalConsultants
                        var premiumCollections = consultantsPlanwise[i].planCost
                     }else if(consultantsPlanwise[i].planName =='Basic'){
                        var basicConsultants = consultantsPlanwise[i].totalConsultants
                        var basicCollections = consultantsPlanwise[i].planCost
                     }
                  }
                  this.setState({
                     totalUsers                : userData.data.totals.totalUsers,
                     totalConsultants          : userData.data.totals.totalConsultants,
                     standardConsultants       : standardConsultants,
                     premiumConsultants        : premiumConsultants,
                     basicConsultants          : basicConsultants,
                     standardCollections       : standardCollections,
                     premiumCollections        : premiumCollections,
                     basicCollections          : basicCollections,
                  })
               })
            .catch(error=>{
                console.log("error => ",error);
            }) 
         var limit =10;
         axios.get("/api/dashboard/get/introductory-calls-by-location-of-consultant/"+fromDate+"/"+toDate+"/"+limit)
            .then(introConsData => {            
               // console.log("introConsData => ",introConsData);
               this.setState({
                  introConsData          : introConsData.data.data,
               })
            })
            .catch(error=>{
                console.log("error => ",error);
            })
      }
            
   }
   getApprovalData(){
      axios.get("/api/consultant-approval/get/enterprise-list")
         .then((response) => {
            if(response.data){
               var entApprovalData = response.data.data;
               var entPendingApproval = 0
               for (var i = 0; i < entApprovalData.length; i++) {
                  if(entApprovalData[i].approvalStatus=="Pending"){
                     entPendingApproval = entPendingApproval+1
                  }
               }
               // console.log("entPendingApproval",entPendingApproval)
               this.setState({
                  entPendingApproval          : entPendingApproval,
               })
            }
            
         })
         .catch((error) => {
            // console.log("Error => ", error);
         });
      axios.get("/api/consultant-approval/get/consultant-list")
         .then((response) => {
            if(response.data){
               var consultantApprovalData = response.data.data;
               var consultantPendingApproval = 0
               for (var i = 0; i < consultantApprovalData.length; i++) {
                  if(consultantApprovalData[i].approvalStatus=="Pending"){
                     consultantPendingApproval = consultantPendingApproval+1
                  }
               }
               // console.log("consultantPendingApproval",consultantPendingApproval)
               this.setState({
                  consultantPendingApproval          : consultantPendingApproval,
               })
            }
            
         })
         .catch((error) => {
            // console.log("Error => ", error);
         });
      axios.get("/api/consultant-approval/get/expertise-catg/consultant-list")
         .then((response) => {
           // console.log("response => ", response.data);

            if(response.data){
               var consExpertiseApprovalData = response.data.data;
               var consExpertiseApproval = 0
               for (var i = 0; i < consExpertiseApprovalData.length; i++) {
                  if(consExpertiseApprovalData[i].status=="Pending"){
                     consExpertiseApproval = consExpertiseApproval+1
                  }
               }
               // console.log("consExpertiseApproval",consExpertiseApproval)
               this.setState({
                  consExpertiseApproval          : consExpertiseApproval,
               })
            }
            
         })
         .catch((error) => {
           // console.log("Error => ", error);
         });
      axios.get("/api/consultant-approval/get/sub-catg/consultant-list")
         .then((response) => {
            // console.log("/sub-catg/consultant-list ===> ", response);
            if(response.data){
               var consSubcategoryApprovalData = response.data.data;
               var consSubcategoryApproval = 0
               for (var i = 0; i < consSubcategoryApprovalData.length; i++) {
                  if(consSubcategoryApprovalData[i].status=="Pending"){
                     consSubcategoryApproval = consSubcategoryApproval+1
                  }
               }
               // console.log("consSubcategoryApproval",consSubcategoryApproval)
               this.setState({
                  consSubcategoryApproval          : consSubcategoryApproval,
               })
            }
         })
         .catch((error) => {
           // console.log("Error => ", error);
         });
      axios.get("/api/consultant-approval/get/expertise-catg/enterprise-list")
         .then((response) => {
            // console.log("expertise response => ", response.data);
            
            if(response.data){
               var entExpertiseApprovalData = response.data.data;
               var entExpertiseApproval = 0
               for (var i = 0; i < entExpertiseApprovalData.length; i++) {
                  if(entExpertiseApprovalData[i].status=="Pending"){
                     entExpertiseApproval = entExpertiseApproval+1
                  }
               }
               // console.log("entExpertiseApproval",entExpertiseApproval)
               this.setState({
                  entExpertiseApproval          : entExpertiseApproval,
               })
            }
         })
         .catch((error) => {
           // console.log("Error => ", error);
         });
      axios.get("/api/consultant-approval/get/sub-catg/enterprise-list")
         .then((response) => {
            // console.log("response => ", response.data);
            if(response.data){
               var entSubcategoryApprovalData = response.data.data;
               var entSubcategoryApproval = 0
               for (var i = 0; i < entSubcategoryApprovalData.length; i++) {
                  if(entSubcategoryApprovalData[i].status=="Pending"){
                     entSubcategoryApproval = entSubcategoryApproval+1
                  }
               }
               // console.log("entSubcategoryApproval",entSubcategoryApproval)
               this.setState({
                  entSubcategoryApproval          : entSubcategoryApproval,
               })
            }
         })
         .catch((error) => {
           // console.log("Error => ", error);
         });
         // blogsPendingApproval
      axios.get("/api/blogs2/blogslist")
         .then((response) => {
            if(response.data){
               var blogsPendingApprovalData = response.data.data;
               var blogsPendingApproval = 0
               for (var i = 0; i < blogsPendingApprovalData.length; i++) {
                  if(blogsPendingApprovalData[i].approvalStatus=="Pending"){
                     blogsPendingApproval = blogsPendingApproval+1
                  }
               }
               // console.log("blogsPendingApproval",blogsPendingApproval)
               this.setState({
                  blogsPendingApproval          : blogsPendingApproval,
               })
            }
         })
         .catch((error) => {
           // console.log("Error => ", error);
         });
   }
   handleDateChange (event){
      // console.log("event.target.value",event.target.value)
      if(event.target.name === "fromDate"){
         if(event.target.value <= this.state.toDate){            
            this.setState({
               fromDate    :event.target.value,
            },()=>{
               this.getData(this.state.fromDate,this.state.toDate)
            })
         }else{
            console.log("From Date must be less than or equal to ToDate")
         }
      }
      if(event.target.name === "toDate"){
         if(event.target.value >= this.state.fromDate){            
            this.setState({
               toDate    :event.target.value,
            },()=>{
               this.getData(this.state.fromDate,this.state.toDate)
            })
         }else{
            console.log("To Date must be greater than or equal to From Date")
         }         
      }
   }
   render() {
      // console.log("this.state",this.state)
      return (

         <div className="container-fluid col-lg-12 col-md-12 col-xs-12 col-sm-12">
            <div className="row">
               <div className=""> 
                  <section className="">
                     <div className="">
                        <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                           <h3 className="col-lg-12 col-md-12 col-xs-12 col-sm-12"><b>Dashboard</b></h3>
                        </div>
                        

                        <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 ">
                           <div className="row">
                              <div className="col-lg-offset-4 col-xs-offset-4 col-lg-3 col-md-offset-2 col-md-4 col-sm-12 col-xs-12  px-0 mb-3 mrlT ">
                                 <label htmlFor="fromDate" className="dateText ml20">From Date </label>
                                 <div className="input-group">                        
                                    {/*<div className="input-group-prependNew col-lg-1 floatLeft">
                                       <span className="input-group-text" id="basic-addon1">
                                          <i className="fa fa-calendar" aria-hidden="true"></i>
                                        </span>
                                    </div>*/}
                                    <input type="date" className="form-controlNew" id="fromDate" name="fromDate" 
                                            onChange={this.handleDateChange.bind(this)} value={this.state.fromDate} />
                                     {/*<div className="errorMsg  text-left pl-0 mt-2">{fromDateErr}</div>*/}
                                 </div>           
                              </div>

                              <div className="col-lg-offset-0 col-lg-3 col-md-offset-0 col-md-4 col-sm-12 col-xs-12 col-xs-offset-4 px-0 mb-3 mrlT">
                                 <label htmlFor="toDate" className="dateText ml20">To Date</label>
                                 <div className="input-group">
                                    {/* <div className=" input-group-prependNew col-lg-1 floatLeft">
                                       <span className="input-group-text" id="basic-addon1">
                                          <i className="fa fa-calendar" aria-hidden="true"></i>
                                       </span>
                                     </div>*/}
                                     <input type="date" className="form-controlNew" id="toDate" name="toDate" 
                                            onChange={this.handleDateChange.bind(this)} value={this.state.toDate} />
                                     {/*<div className="errorMsg  text-left pl-0 mt-2">{toDateErr}</div>*/}
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 ">                          
                           <StatusComponent stats={{
                              color:"#ffbb33",
                              heading1:"Basic",
                              value1:this.state.basicConsultants, 
                              value2:this.state.basicCollections}} />
                           <StatusComponent stats={{
                              color:"#007E33",
                              heading1:"Standard",
                              value1:this.state.standardConsultants, 
                              value2:this.state.standardCollections}} />
                           <StatusComponent stats={{
                              color:"#0099CC",
                              heading1:"Premium",
                              value1:this.state.premiumConsultants, 
                              value2:this.state.premiumCollections}} />
                        </div>
                        <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 ">
                           <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12">
                              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 collectionBox peach-gradient">                          
                                 <div className="collectionText col-lg-6 col-md-6 col-sm-6 col-xs-12">Gross Collections  </div>
                                 <div className="mt-3 mt-lg-1 text-right boxNum white  col-lg-6 col-md-6 col-sm-6 col-xs-12">{this.state.totalBill ? this.state.totalBill : 0}</div>
                              </div>                       
                           </div>   
                           <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12">
                              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 collectionBox green-gradient">                          
                                 <div className="collectionText col-lg-6 col-md-6 col-sm-6 col-xs-12">Consultant Share  </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-3 mt-lg-1 text-right boxNum white">{this.state.totalBill ? (this.state.totalBill)*0.5 : 0}</div>
                              </div>                       
                           </div>   
                           <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12">
                              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 collectionBox blue-gradient">                          
                                 <div className=" col-lg-6 col-md-6 col-sm-6 col-xs-12 collectionText"> Portal's Share  </div>
                                    <div className=" col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-3 mt-lg-1 text-right boxNum white">{this.state.totalBill ? (this.state.totalBill)*0.5 : 0}</div>
                              </div>                       
                           </div>   
                        </div>   
                        <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 "> 
                           <div className="col-lg-5 col-md-12 col-xs-12 col-sm-12 ">
                              <PieChart
                                 title="Location wise Consultants"
                                 display={true}
                                 boxColor="#ffc107"
                                 fromDate = {this.state.fromDate}
                                 toDate = {this.state.toDate}
                                 api={{'method': "get", 'path':"/api/dashboard/get/all-location-wise-consultants/"}}
                                 // api={{'method': "get", 'path':"/api/dashboard/get/all-location-wise-consultants/"+this.state.fromDate+"/"+this.state.toDate}}
                              />
                           </div>
                           <div className="col-lg-3 col-md-12 col-xs-12 col-sm-12 ">
                              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 userBox">                          
                                 <div className="mt-30">Total Users<br /> 
                                    <div className="mt-3 mt-lg-1  boxNum">{this.state.totalUsers ? this.state.totalUsers : 0}</div>
                                 </div>
                              </div>                
                              <div className="row">          
                                 <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 ">
                                    <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 callsBox">                          
                                       <div className="">Upcoming Calls<br /> 
                                          <div className="mt-3 mt-lg-1  boxNum">{this.state.totalFutureCalls ? this.state.totalFutureCalls : 0}</div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                                    <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 callsBox1">                         
                                       <div className="">Completed Calls<br /> 
                                          <div className="mt-3 mt-lg-1  boxNum">{this.state.totalCompletedCalls ? this.state.totalCompletedCalls : 0}</div>
                                       </div>
                                    </div>                              
                                 </div>
                                 <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                                    <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 callsBox">                          
                                       <div className="">Cancelled Calls<br /> 
                                          <div className="mt-3 mt-lg-1  boxNum">{this.state.totalCancelledCalls ? this.state.totalCancelledCalls : 0}</div>
                                       </div>
                                    </div>                              
                                 </div>
                                 <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                                    <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 callsBox1">                          
                                       <div className="">Total Calls<br /> 
                                          <div className="mt-3 mt-lg-1  boxNum">{this.state.totalFutureCalls+this.state.totalCompletedCalls+this.state.totalCancelledCalls}</div>
                                       </div>
                                    </div>                              
                                 </div>
                              </div>
                           </div>     
                           <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12 ">
                           <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 chartBox">
                              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 blue-text text-primary">Pending Approvals</div>                          
                              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 approvalBox">                          
                                 <div className="col-lg-10 col-xs-10 ">Enterprise Profiles</div>
                                 <div className="text-right approvalCount col-lg-1 col-xs-1 blue-gradient" style={{color:"#fff"}}>{this.state.entPendingApproval ? this.state.entPendingApproval : 0}</div>
                              </div>                       
                              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 approvalBox">                          
                                 <div className="col-lg-10 col-xs-10">Enterprise: Others : Subcategories</div>
                                 <div className="text-right approvalCount col-lg-1 col-xs-1 blue-gradient" style={{color:"#fff"}}>{this.state.entSubcategoryApproval ? this.state.entSubcategoryApproval : 0}</div>
                              </div>                       
                              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 approvalBox">                          
                                 <div className="col-lg-10 col-xs-10">Enterprise: Others : Expertises</div>
                                 <div className="text-right approvalCount col-lg-1 col-xs-1 blue-gradient" style={{color:"#fff"}}>{this.state.entExpertiseApproval ? this.state.entExpertiseApproval : 0}</div>
                              </div>                                
                              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 approvalBox">                          
                                 <div className="col-lg-10 col-xs-10">Consultant Profiles</div>
                                 <div className="text-right approvalCount col-lg-1 col-xs-1 blue-gradient" style={{color:"#fff"}}>{this.state.consultantPendingApproval ? this.state.consultantPendingApproval : 0}</div>
                              </div>                       
                              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 approvalBox">                          
                                 <div className="col-lg-10 col-xs-10">Consultant: Others : Subcategories</div>
                                 <div className="text-right approvalCount col-lg-1 col-xs-1 blue-gradient" style={{color:"#fff"}}>{this.state.consSubcategoryApproval ? this.state.consSubcategoryApproval : 0}</div>
                              </div>                       
                              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 approvalBox">                          
                                 <div className="col-lg-10 col-xs-10">Consultant: Others : Expertises</div>
                                 <div className="text-right approvalCount col-lg-1 col-xs-1 blue-gradient" style={{color:"#fff"}}>{this.state.consExpertiseApproval ? this.state.consExpertiseApproval : 0}</div>
                              </div>                       
                              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 approvalBox">                          
                                 <div className="col-lg-10 col-xs-10">Blogs</div>
                                 <div className="text-right approvalCount col-lg-1 col-xs-1 blue-gradient" style={{color:"#fff"}}>{this.state.blogsPendingApproval ? this.state.blogsPendingApproval : 0}</div>
                              </div>              
                           </div>                         
                           </div>                         
                        </div>                            
                        <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 ">                          
                           
                           <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 ">
                                 <PieChart
                                    title="Location wise Users"
                                    display={true}
                                    boxColor="#ffc107"
                                    fromDate = {this.state.fromDate}
                                    toDate = {this.state.toDate}
                                    api={{'method': "get", 'path':"/api/dashboard/get/all-location-wise-users/"}}
                                    // api={{'method': "get", 'path':"/api/dashboard/get/all-location-wise-users/"+this.state.fromDate+"/"+this.state.toDate}}
                                 />
                           </div>                              
                           <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 ">
                              <BarChart
                                 title="Introductory Calls by Location of Consultants"
                                 display={true}
                                 // boxColor="#ffc107"
                                 boxColor='rgba(53, 162, 235, 0.9)'
                                 fromDate = {this.state.fromDate}
                                 toDate = {this.state.toDate}
                                 api={{'method': "get", 'path':"/api/dashboard/get/call-by-location-wise-consultants/"}}
                              />
                           </div>
                           <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 ">
                              <BarChart
                                 title="Introductory Calls by Location of Users"
                                 display={true}
                                 boxColor='rgba(255, 152, 0, 0.7)'
                                 fromDate = {this.state.fromDate}
                                 toDate = {this.state.toDate}
                                 api={{'method': "get", 'path':"/api/dashboard/get/call-by-location-wise-users/"}}
                              />
                           </div>   
                           <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 ">
                              <BarChart
                                 title="Introductory Calls by Category of Consultants"
                                 display={true}
                                 // boxColor="#ffc107"
                                 boxColor='rgba(255, 99, 132, 1)'
                                 fromDate = {this.state.fromDate}
                                 toDate = {this.state.toDate}
                                 api={{'method': "get", 'path':"/api/dashboard/get/call-by-category-wise-consultants/"}}
                              />
                           </div>
                           <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 ">
                              <BarChart
                                 title="Introductory Calls by Category of Users"
                                 display={true}
                                 boxColor='rgba(156, 39, 176, 0.7)'
                                 fromDate = {this.state.fromDate}
                                 toDate = {this.state.toDate}
                                 api={{'method': "get", 'path':"/api/dashboard/get/call-by-category-wise-users/"}}
                              />
                           </div>
                        </div>         
                                                 
                     </div>
                     



                  </section>
               </div>
            </div>
         </div>
      );
    }
}



const mapStateToProps = (state)=>{
  console.log(" state on Dashboard ==> ",state)
  return {
    userDetails   : state.userDetails,
  }
};


const mapDispatchToProps = (dispatch)=>{
  return {
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));

