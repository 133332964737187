import React,{Component} from 'react';
import { render } from 'react-dom';
import {Pie} from 'react-chartjs-2';
// import 'chartjs-plugin-labels';
import axios             from 'axios';
import { connect }               from 'react-redux';
import { withRouter }           from 'react-router-dom';
import moment                   from 'moment';




class PieChart extends Component{
  
   constructor(props) {
      super(props);
      this.state = {
         width:300,
         boxColor:props.boxColor,
         title:props.title,
         display:props.display,
         columns:props.columns,
         data : {},
         options : {
            legend: {
               // display: false
               position:"right"
             },
            plugins: {
                 labels: [
                   // {
                   //   render: 'label',
                   //   precision: 2,
                   //   // position: 'outside'
                   // },
                   // {
                   //   render: 'value',
                   //   position: 'outside'

                   // }
                 ],
         
            // datalabels: {
            //       display:false
            //  }, 
           }
         },
      }
   }
   
   componentDidMount(){
      const userDetails  = localStorage.getItem("userDetails");
      if(userDetails){
         const userDetailsParse  = JSON.parse(userDetails);
         var user_id = userDetailsParse.user_id;
         // console.log("window.innerWidth",window.innerWidth);
         if(this.props.display){
            this.setState({
               boxColor : this.props.boxColor,
               title    : this.props.title,
               apiData  : this.props.api,
               columns  : this.props.columns,
               user_id  : user_id
            },()=>{
               this.getData(this.props.fromDate, this.props.toDate)
            })
         }
      }

      if(window.innerWidth < 414){
         this.setState({
              "width" : 400
         },()=>{
                // console.log("Width===",this.state.width);
         })        
      }else{
         this.setState({
              "width" : 350
         },()=>{
            // console.log("width===",this.state.width);
         })    
      }
   }

   componentWillReceiveProps(nextProps){
      // console.log("this.props componentWillReceiveProps",this.props)
      this.getData(this.props.fromDate, this.props.toDate)
   }

   // getData(fromDate,toDate){
   //    // console.log("this.state.apiData => ",this.state.apiData);

   //    if(this.state.apiData){
   //       var method = this.state.apiData.method;
   //       var path = this.state.apiData.path;
   //       //var formValues = this.state.apiData.formValues;
   //       //formValues.user_id = this.state.user_id
   //       axios({
   //          method: method,
   //          url: path+fromDate+"/"+toDate,
   //          //data:formValues
   //       })
   //         .then((response)=>{ 
   //             var planName = [];
   //             var totalBill =[];
   //             // console.log("piechart response=", response);
   //             if(response.data && response.data.data && response.data.data.length>0){
   //                response.data.data.map((data,index)=>{ 

   //                   planName.push(data.planName);
   //                   totalBill.push(data.totalBill);
   //                })

   //                if(planName.length > 0){
   //                   // console.log("planName => ",planName);
   //                   // console.log("totalBill => ",totalBill);
   //                   this.setState({
   //                      planName:planName.slice(0,10),
   //                      totalBill:totalBill.slice(0,10)
   //                   });
   //                }else{
   //                   this.setState({
   //                      planName:[],
   //                      totalBill:[]
   //                   });
   //                }
   //             }  
   //       })
   //       .catch((error)=>{  
   //         console.log('error=>',error)      
   //       });
   //    }
   // }
   getData(fromDate,toDate){
      // var data = {...this.state.data};

      if(this.state.apiData){
         var method = this.state.apiData.method;
         var path = this.state.apiData.path;
         //var formValues = this.state.apiData.formValues;
         //formValues.user_id = this.state.user_id
         axios({
            method: method,
            url: path+fromDate+"/"+toDate,
           // data:formValues
         })
        .then((response)=>{ 
            // console.log(" response.data => ",response)
            var city = [];
            var totalData = [];
            if(response.data && response.data.totals && response.data.totals.length>0){
               response.data.totals.map((data,index)=>{ 
                  city.push(data.city);
                  totalData.push(data.totalData);
               })
            }  
            if(city.length > 0){
               this.setState({city:city.slice(0,10),totalData:totalData.slice(0,10)});
            }else{
               this.setState({city:[],totalData:[]});
            }
         })
         .catch((error)=>{  
            console.log('error=>',error)      
         });
      }
   }
    
   render(){
      // console.log("render this.state.data => ",this.state)
      return(
         <div className="col-lg-12  col-md-12 col-xs-12 col-sm-12  chartBox chartBoxNew">
            <h4 className="graphFont text-center"><b>{this.state.title}</b></h4>
            <div className="mx-auto pt-3 w-sm-100" style={{ width: this.state.width }}>
               {
                  this.state.city && this.state.city.length>0 && this.state.totalData
                  ?
                     <Pie data={{
                           labels: this.state.city,
                           datasets: [{
                              id : 1,
                              label: "Category wise Revenue",
                              data: this.state.totalData,
                              backgroundColor: [
                                                   "rgba(255, 99, 132, 1)",
                                                   "rgba(54, 162, 235, 1)",
                                                   "rgba(255, 206, 86, 1)",
                                                   "rgba(75, 192, 192, 1)",
                                                   "rgba(153, 102, 255, 1)",
                                                   "rgba(255, 159, 64, 1)",
                                                   "rgba(200, 199, 132, 1)",
                                                   "rgba(233, 30, 99, 0.7)",
                                                   "rgba(154, 12, 235, 1)",
                                                   "rgba(225, 92, 192, 1)",
                                                   "rgba(255, 152, 0, 0.7)" 
                                                ],
                              borderWidth: 0,
                                labelAlign: 'center'

 
                           }]
                     }} width={50} height={50} options={this.state.options} />
                  :
                     <div className="comingSoon text-center">
                        <small><small> Data not available </small></small>
                     </div>
               }
            </div>        
         </div>
      );
   }
}
export default (PieChart);