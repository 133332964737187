import React, {Component}  from 'react';
import axios               from 'axios';
import moment              from 'moment';
import Swal                from  'sweetalert2';

import "./DetailedBlog.css";

class DetailedBlog extends Component{
   constructor(props) {
      super(props);
      this.state = {
         progressBarWidth : 0,
         pbPosition : "absolute",
         pbTop : 0,
         user_id : "",
         blogData : {},
         profileImg : ""
      }
   }
   componentDidMount() {
      var url=window.location.href;

      const userDetails       = localStorage.getItem("userDetails");
         if(userDetails){
            const userDetailsParse  = JSON.parse(userDetails);

            if(this.props.blogData && this.props.blogData.usersLiked && this.props.blogData.usersLiked.indexOf(userDetailsParse.user_id) > -1){
               this.setState({
                  blogData : {...this.props.blogData, isUserLike:true },
                  shareUrl : url,
                  user_id : userDetailsParse.user_id,
                  profileImg :this.props.blogData && this.props.blogData.createdBy && this.props.blogData.createdBy.documents && this.props.blogData.createdBy.documents.profilePhoto.length > 0 && this.props.blogData.createdBy.documents.profilePhoto[0].url ? this.props.blogData.createdBy.documents.profilePhoto[0].url : "/images/no-image.jpg"
               },
               ()=>{
                     // console.log("1 this.state.blogData => ",this.state.blogData)
                    //console.log("2 this.state.user_id => ",this.state.user_id)
                     }
               );                     
            }else{
               this.setState({
                  blogData : {...this.props.blogData, isUserLike:false },
                  shareUrl : url,
                  user_id : userDetailsParse.user_id,
                  profileImg :this.props.blogData && this.props.blogData.createdBy && this.props.blogData.createdBy.documents && this.props.blogData.createdBy.documents.profilePhoto.length > 0 && this.props.blogData.createdBy.documents.profilePhoto[0].url ? this.props.blogData.createdBy.documents.profilePhoto[0].url : "/images/no-image.jpg"
               },
                  ()=>{
                     // console.log("2 this.state.blogData => ",this.state.blogData)
                  }
               );                     
            }
         }else{
            this.setState({
               blogData : {...this.props.blogData, isUserLike:false },
               shareUrl : url,
               user_id : 0,
               profileImg :this.props.blogData && this.props.blogData.createdBy && this.props.blogData.createdBy.documents && this.props.blogData.createdBy.documents.profilePhoto.length > 0 && this.props.blogData.createdBy.documents.profilePhoto[0].url ? this.props.blogData.createdBy.documents.profilePhoto[0].url : "/images/no-image.jpg"
            },
            ()=>{
               // console.log("3 this.state.blogData => ",this.state.blogData)
            });                     
         }
      window.addEventListener('scroll', this.handleScroll);
      
      // var url = window.location.hostname+"/blogs/"+this.props.blogData.blogURL

      // console.log("url => ", url);


   }

   componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
   }

   handleScroll = (event)=>{
      let scrollHeight = event.srcElement.body.scrollHeight - 800;
      let scrollWidth = event.srcElement.body.scrollWidth;
      let scrollY = window.scrollY;
      // let windowHeight = screen.height;
      let progressBarWidth = parseInt(scrollY/scrollHeight * scrollWidth);
      if(scrollY >= 74){
         this.setState({pbPosition:"fixed", pbTop:0});
      }
      if(scrollY < 74){
         this.setState({pbPosition:"absolute", pbTop:75});
      }

      this.setState({progressBarWidth:progressBarWidth});
   }

   saveBlog=(event)=>{
      var blog_id = event.target.getAttribute('id').split("-");
      if(blog_id[1]){
         var formValues = {
            user_id           : this.state.user_id,
            blog_id           : blog_id[1]
         }
         axios.patch("/api/blogs2/patch/blog",formValues)
            .then(updateData =>{
               if(updateData.data.success){
                  if(updateData.data.blogDetails.usersLiked && updateData.data.blogDetails.usersLiked.indexOf(this.state.user_id) > -1){
                     this.setState({
                        blogData : {...updateData.data.blogDetails, isUserLike:true }
                     },
                     ()=>{console.log("this.state.blogData => ",this.state.blogData)}
                     );                     
                  }else{
                     this.setState({
                        blogData : {...this.state.blogData, isUserLike:false }
                     },
                     ()=>{console.log("this.state.blogData => ",this.state.blogData)}
                     );                     
                  }
                  //console.log("blogData==",this.state.blogData);
               }else{
                  Swal.fire("Some issue while saving blog!",updateData.data.error,"error");
               } 
            })
            .catch(error=>{
               console.log("Error => ",error);
            })         
      }
   }
   
   deleteBlog=(event)=>{
      const blog_id = event.currentTarget.id;
      console.log("blog_id",blog_id);

      Swal.fire({
        title: 'Are you sure you want to delete this blog?',
        text: "Once deleted, you won't be able to revert this action!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0d2342',
        cancelButtonColor: '#ffD700',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
         if (result.isConfirmed) {

            if(blog_id){   
               axios.delete("/api/blogs2/delete/delete-blog/"+blog_id)
                  .then((response) =>{
                     if(response.data.message === "Blog deleted Successfully"){
                        if (typeof window !== "undefined") {
                           window.location.href = "/consultant/my-blogs";
                           window.location.assign("/consultant/my-blogs");
                           Swal.fire(
                              'Deleted!',
                              'Blog has been deleted successfully!',
                              'success'
                           );
                        }
                     }
                  })
                  .catch(error=>{
                      Swal.fire(
                        'Some Error Occured During Delete',
                         error.message,
                        'error'
                      )
                  })          
            }
        }
      })

   }
   render(){
      //console.log("blogHeaderImage",this.props.blogData);
      return(
   		<section className="main" id="mainblk">
            <div className="pageProgressBar" 
                  style={{
                     width    : this.state.progressBarWidth,
                     position : this.state.pbPosition,
                     top      : this.state.pbTop,
                  }}></div>
            <div className="pageContent col-lg-12"> 
               <div className={"col-lg-12  mx-auto mb-5 pageWrapper "}>
                  <div> 
                     <div className="row"> 
                        <div className="col-lg-12 col-md-11 mb15 blogTitle">
                           {this.state.blogData.blogTitle}
                        </div>                     
                     </div>
                     
                     <div className="col-lg-12 mb15"> 
                        <div className="col-2 col-lg-1 px-0 ">
                           <img className={"ml-lg-2 userImg"} src={this.state.profileImg && this.state.profileImg !=="" ? this.state.profileImg : "/images/userProfile.png"} />
                        </div>
                        <div className="col-10 col-lg-11 pl-0 ">
                           <div className="row">
                              <div className={"col-lg-7 pr-0  mb-2 pubDate"}>
                                 <b>Published On - {moment(this.state.blogData.createdAt).format("MMMM DD, YYYY")}</b>
                              </div>
                              <div className={"col-lg-5 text-right mb-2 px-2 pl-lg-5 pr-lg-3 pubDate"}>
                                 {this.state.blogData.readingInMin >= 2 ? parseInt(this.state.blogData.readingInMin)-1 : this.state.blogData.readingInMin} min read 
                              </div>
                           </div>
                           {/* <div className="row" style={{flexWrap: "wrap"}}> */}
                           <div>
                              <div className="pubDate" style={{display: "flex", alignItems: "center"}}>
                                 {/*<span className={"mx-3 pubDate"}>*/}
                                    <i className={"fa fa-user-o mr-2 userIcon"} ></i>
                                     <div className="category"><b>{this.state.blogData.createdBy && this.state.blogData.createdBy.profile ? (this.state.blogData.createdBy.profile.fullName) : "-"}</b> </div>
                        
                                 {/*</span>*/}
                              </div>
                              <div className="pubDate" style={{display: "flex", alignItems: "center"}}>
                                 <i className={"fa fa-th-large mr-2 appIcon"} aria-hidden="true" ></i>
                                 <div className="category">{this.state.blogData.blogCategory}</div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="col-12 "> 
                        <div className="col-lg-12 px-0 px-lg-2 mt-3 mb15">
                           <img src={this.state.blogData.blogHeaderImage && this.state.blogData.blogHeaderImage.path  ? this.state.blogData.blogHeaderImage.path : "/images/no-image.jpg"} className="heroImg" />
                        </div>
                        {/*<i> <div className="col-lg-12 my-5 descFont" dangerouslySetInnerHTML={{__html: this.state.blogData.blogSummary}}>  </div> </i>*/}
                        <div className="col-lg-12 mt-4 descFont" dangerouslySetInnerHTML={{__html: this.state.blogData.blogContent}} /> 
                     </div>
                  </div>
                  <hr className="col-11 mx-auto my-2" />

                  <div className="col-lg-12 mt-5 border p-5 bg-light">
                     <div className="disclaimer">
                        <b> Disclaimer : </b>
                        The views expressed in this Article / Blog / Write up are the matters of opinion 
                        and shall not be construed as an advice issued. The views expressed are based on 
                        the understanding of law and regulations prevailing at the date of writing this 
                        Article and on the past experience of the Author. There can be no assurance that 
                        the regulators may not take a position contrary to the views expressed herein.
                     </div>
                  </div>


			    	</div>
            </div>
   		</section>
   	);
      }
   }
 
export default DetailedBlog;