import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";

export default () => {
  const [consultantProfiles, setConsultantProfiles] = useState([]);
  const [consultantProfile, setConsultantProfile] = useState({});
  const [showTable, setShowTable] = useState(true);

  useEffect(() => {
    fetchConsultantProfiles();
  }, []);

  const fetchConsultantProfiles = () => {
    axios
      .get("/api/consultant-approval/get/enterprise-list")
      .then((response) => {
        // console.log("response => ", response.data);
        setConsultantProfiles(response.data.data);
      })
      .catch((error) => {
        console.log("Error => ", error);
      });
  };

  const handleToggleView = (data) => {
    setConsultantProfile(!data ? {} : data)
    setShowTable(!showTable)
  }
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding">
      <section className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding content">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pageContent">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 NOPadding topMargin box-header with-border">
              <h4 className="col-lg-12 col-md-12 col-xs-12 col-sm-12 weighttitle NOPadding">
                Enterprise Profiles
              </h4>
            </div>
          </div>
          {showTable ? <Table
            data={consultantProfiles}
            fetchConsultantProfiles={fetchConsultantProfiles}
            handleToggleView={handleToggleView}
          /> :
            <ProfileDetails
              handleToggleView={handleToggleView}
              consultantDetails={consultantProfile}

            />}
        </div>
      </section>
    </div>
  );
};

const Table = (props) => {
  const [loader, setLoader] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [filter, setFilter] = useState({});

  const columns = [
    {
      key: "enterpriseName",
      name: "Enterprise Name",
      colSize: "w100",
      type: "string",
      isSortable: true,

      children: (props) => <ProfileDetailsLink {...props} />,
    },
    {
      key: "consultantName",
      name: "Consultant Name",
      colSize: "w100",
      type: "string",
      isSortable: true,
    },
    {
      key: "address",
      name: "Address",
      colSize: "w150",
      type: "string",
      isSortable: true,
    },
    {
      key: "emailAddress",
      name: "Email Address",
      colSize: "w100",
      type: "string",
      isSortable: true,
    },
    {
      key: "registrationDate",
      name: "Reg. Date",
      colSize: "w100",
      type: "string",
      isSortable: true,
    },
    {
      key: "",
      name: "Action",
      colSize: "w100",
      type: "string",
      isSortable: true,
      children: (props) => <ModalBtn {...props} />,

    },
    {
      key: "approvalStatus",
      name: "Status",
      colSize: "w100",
      type: "string",
      isSortable: true,
      children: (props) => <ShowStatus {...props} />,      
    },
    {
      key: "approvalDate",
      name: "Action Date",
      colSize: "w100",
      type: "string",
      isSortable: true,
    },
    {
      key: "remark",
      name: "Remark",
      colSize: "w100",
      type: "string",
      isSortable: true,
    },
  ];

  const [page, setPage] = useState(1);
  let pages = 2;
  const getPaginatedEntries = () => {
    pages = props.data.length > 0 ? props.data.length - 1 : 1;
    return props.data.slice((page - 1) * pageLimit, page * pageLimit);
  };

  const handlePageLimit = (e) => {
    setPageLimit(parseInt(e.target.value));
  };

  const dataRows = getPaginatedEntries();

  const handleSort = (event) => {
    setFilter({
      ...filter,
      sort: { key: event.target.id, type: filter?.sort?.type === 1 ? 0 : 1 },
    });
  };

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="row justify">
        {/* <FilterComponent
                handlePageLimit={handlePageLimit}
                pageLimit={pageLimit}
                filter={filter}
                handleFilter={setFilter}
                setPage={setPage}
                dataList={dataRows1}
            /> */}
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 table-responsive">
        {/*<div className="pt-3 pl-0 col-8 overflow-auto scroll-auto">*/}
          <table className="table table-hover table-bordered table-striped table-height tableFixHead">
            <thead className="tempTableHeader">
              <tr>
                <th scope="col">#</th>
                {columns.map((column) => (
                  <th
                    scope="col"
                    className={` ${filter?.sort?.key === column.key && "text-primary" 
                      }  text-uppercase  colSize`}
                  >
                    {column.name}
                    {/* {column.isSortable && (
                      <i
                        id={column.key}
                        value={column.key}
                        className={`fa fa-fw  ${
                          column.key !== filter?.sort?.key && "fa-sort"
                        } ${
                          column.key === filter?.sort?.key &&
                          (filter?.sort?.type === 1
                            ? "fa-arrow-up text-warning"
                            : "fa-arrow-down text-warning")
                        }`}
                        // onClick={handleSort}
                      ></i>
                    )} */}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              { dataRows
                ?
                  dataRows.length > 0 
                  ? 
                    (
                    dataRows.map((data, index) => (
                      <tr>
                        <td className=" text-center align-middle">
                          {page === 1
                            ? index + 1
                            : `${(page === 1 ? 1 : page - 1) * pageLimit + (index + 1)
                            }`}
                        </td>
                        {columns.map((column, index) => (
                          <td
                            key={index}
                            className={` ${column.key === "status" ? "text-center" : "text-left"
                              } align-middle `}
                          >
                            {column.children ? (
                              <column.children
                                fetchConsultantProfiles={
                                  props.fetchConsultantProfiles
                                }
                                handleClick={() => props.handleToggleView(data._doc)}
                                data={data}
                                title={data[column.key] ?? ""}
                              />
                            ) : (
                              data[column.key]
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                    ) 
                  :
                    (
                      <tr className="trAdmin">
                      {console.log("loader",loader)}
                      {loader ? (
                        <div className="loadingImg">
                          <img src="/images/loading.gif" />
                        </div>
                      ) : (
                        <td colSpan="10" className="noTempData textAlignCenter">No Record Found!</td>
                      )}
                      </tr>
                  )
                :
                  <tr className="trAdmin">
                      <td colSpan="10" className="noTempData textAlignCenter">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        <span className="sr-only">Loading...</span>                 
                      </td>
                  </tr>   
              }
            </tbody>
          </table>
        </div>
        <TablePagination
          currentPage={page}
          pages={pages}
          setPage={setPage}
          pageLimit={pageLimit}
          handlePageLimit={handlePageLimit}
        />
      </div>
    </div>
  );
};

const TablePagination = ({
  currentPage,
  pages,
  setPage,
  pageLimit,
  handlePageLimit,
}) => {
  const numPages = Math.ceil(pages / pageLimit);

  const renderPageBlocks = () => {
    return (
      <>
        {currentPage !== 1 && (
          <li className="page-item">
            <a className="page-link" href="#" onClick={goToPrevPage}>
              {currentPage - 1}
            </a>
          </li>
        )}
        <li className="page-item active" disabled>
          <a className="page-link" href="#">
            {currentPage}
          </a>
        </li>
        {currentPage < numPages && (
          <li className="page-item">
            <a className="page-link" href="#" onClick={goToNextPage}>
              {currentPage + 1}
            </a>
          </li>
        )}
      </>
    );
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < numPages) {
      setPage(currentPage + 1);
    }
  };

  const renderPrevPageBlocks = () => {
    return (
      <li className="page-item">
        <a className="page-link" href="#" onClick={() => setPage(1)}>
          &#171;
        </a>
      </li>
    );
  };

  const renderNextPageBlocks = () => {
    return (
      <li className="page-item">
        <a className="page-link" href="#" onClick={() => setPage(numPages)}>
          &raquo;
        </a>
      </li>
    );
  };

  const renderTotals = () => {
    return (
      <li className="mx-2 col-8">
        <h6>{`Showing ${currentPage} of ${numPages}`} Pages</h6>
      </li>
    );
  };

  return (
    <nav aria-label="Page navigation" className="text-center align-middle mb-5">
      <ul className="pagination col-12 d-flex justify-content-center flex-direction-row">
        {renderTotals()}
        {renderPrevPageBlocks()}
        {renderPageBlocks()}
        {renderNextPageBlocks()}
      </ul>
    </nav>
  );
};

const ModalBtn = (props) => {
  // console.log(props);
  const handleClick = () => {

    swal("Remark:", {
      content: "input",
      attributes: {
        placeholder: 'Please provide reason.',
        required: true
      },
      closeOnClickOutside: false,
      button: {
        text: "Save",
      },
    }).then((value) => {
      if(value){
        handleApprovalFeedback("Rejected", value);
      }else{
        swal(" ","Please provide reason.")
      }
    
    });
  }

  const handleApprovalFeedback = (status, remark) => {
    axios
      .post("/api/consultant-approval/post/enterprise-approval", {
        enterprise_id: props.data.enterprise_id,
        consultantName: props.data.consultantName,
        status: status,
        remark: remark,
        email: props.data.emailAddress,
        phoneNumber: props.data.phoneNumber,
      })
      .then((response) => {
        props.fetchConsultantProfiles();
        if (response.data.success && response.data.status==="Approved") {
          swal(" ",props.data.enterpriseName + " has been approved successfully");
        } else if(response.data.success && response.data.status==="Rejected"){
          swal(" ",props.data.enterpriseName + " has been rejected");
        } else {
          swal(" ",props.data.enterpriseName + " has not updated");
        }
      })
      .catch((error) => {
        console.log("Error => ", error);
        swal(" ", "Response not submitted.");
      });
  };

   if(props.data.approvalStatus === "Pending"){
      return <div style={{"width":"150px"}}>
         <button className='btn btn-success col-2' onClick={() => handleApprovalFeedback('Approved')} >Approve</button> &nbsp;
         <button className='btn btn-danger col-2' onClick={handleClick}>Reject</button>
      </div>;      
   }else{
      return null ;
   }
};

const ShowStatus = (props)=>{
   if(props.data.approvalStatus === "Approved"){
      var textColour = "#28a745";
   }
   if(props.data.approvalStatus === "Rejected"){
      var textColour = "#dc3545";
   }
   if(props.data.approvalStatus === "Pending"){
      var textColour = "#ffc107";
   }
   return <div style={{fontWeight : "bold",fontSize:'15px',color:textColour}}> {props.data.approvalStatus} </div>
}
const ProfileDetailsLink = (props) => {
  return <a onClick={props.handleClick}>{props.title}</a>;
}

const ProfileDetails = (props) => {
  console.log(props);
  const {
    enterpriseName, experience, address: { addressLine, city },
    GSTNumber, PANNumber, totalStaff, aboutCompany, catg_subCatg_expertise
    ,documents } = props.consultantDetails

    const download = (href,fileName) => {
      if(!href)return
      fetch(href, {
        method: "GET",
        headers: {
          'Access-Control-Allow-Origin':'*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
        }
      })
        .then(response => {
          response.arrayBuffer().then(function(buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName+".png"); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch(err => {
          console.log(err);
        });
    };


            console.log("documents",documents)
  return <>
    <button className="pull-right" onClick={props.handleToggleView}>X</button>
    <section>
      <table class="table table-bordered">
        <tbody>
          <tr >
            <td colSpan="2"><h5><b>Basic Details</b></h5></td>
          </tr>
          <tr>
            <td className="col-lg-4">Enterprise Name</td>
            <td>{enterpriseName}</td>
          </tr>

          <tr>
            <td className="col-lg-4">Experience</td>
            <td>{experience ?? 'NA'}</td>
          </tr>

          <tr>
            <td className="col-lg-4">Address</td>
            <td>{`${addressLine}, ${city}`}</td>
          </tr>

          <tr>
            <td className="col-lg-4">PAN No.</td>
            <td>{PANNumber ?? 'NA'}</td>
          </tr>

          <tr>
            <td className="col-lg-4">GST Number</td>
            <td>{GSTNumber ?? 'NA'}</td>
          </tr>

          <tr>
            <td className="col-lg-4">Staff Strength</td>
            <td>{totalStaff ?? 'NA'}</td>
          </tr>

          <tr>
            <td className="col-lg-4">About Company</td>
            <td>{aboutCompany ?? 'NA'}</td>
          </tr>
        </tbody>
      </table>

    <table class="table table-bordered">
        <tbody>
          <tr>
            <td colSpan="2"><h5><b>Area Of Practice</b></h5></td>
          </tr>
          {catg_subCatg_expertise.map(category => {
            return <tr>
              <td className="col-lg-4">
                Main Category<br />
                <br />
                Sub Category<br />
                <br />
                Expertise Category<br />
              </td> 
              <td>
                {category.category}<br />
                <br />
                {
                  category?.subCategory?.length > 0 ? category.subCategory.map(subC => subC.businessSubCategory).join(', ') : 'NA'
                }<br /><br />
                {
                  category?.expertise?.length > 0 ? category.expertise.map(expertise => expertise.expertise).join(', ') : 'NA'
                }
                <br />
              </td>
            </tr>
          })}

        </tbody>
      </table>
   
      <table class="table table-bordered">
        {
          documents
          ?
            <tbody>
              <tr>
                <td colSpan="2"><h5><b>Uploaded documents</b></h5></td>
              </tr>
              <tr>
                <td className="col-lg-4">Company Logo</td>
                <td> 
                  <img
                    style={{ width: 500, height: 400, margin: 10 }}
                    src={documents?.companyLogo?.length && documents.companyLogo[0]?.url}
                    onClick={()=>download(documents?.companyLogo?.length && documents.companyLogo[0]?.url,'Company Logo')}>
                  </img>
                </td>
              </tr>

              <tr>
                <td>PAN Card</td>
                <td> 
                  {
                    (documents?.panCard?.length && (documents?.panCard[0]?.url).split('.').pop() ==="png" || (documents?.panCard[0]?.url).split('.').pop() === "jpg"|| (documents?.panCard[0]?.url).split('.').pop() === "PNG"|| (documents?.panCard[0]?.url).split('.').pop() === "JPG")
                    ? 
                      <img
                        style={{ width: 500, height: 400, margin: 10 }}
                        src={documents?.panCard?.length && documents.panCard[0]?.url}
                        onClick={()=>download(documents?.panCard?.length && documents.panCard[0]?.url,'Pan card')}
                        ></img>
                    :
                      ""
                  }
                  {
                    (documents?.panCard?.length && (documents?.panCard[0]?.url).split('.').pop() ==="pdf" || (documents?.panCard[0]?.url).split('.').pop() === "PDF")
                    ? 
                      <a href={documents?.panCard[0]?.url} className="mt" onClick={()=>download(documents?.panCard?.length && documents?.panCard[0]?.url,'Pan Card')}><img className="fileExt"  alt="Pan_Card" src="/images/pdf.png"/> </a>
                    :
                      ""
                  }
                </td>
              </tr>
              <tr>
                <td>Address Proof</td>
                <td> 
                  {
                    documents?.AddressProof && documents?.AddressProof.length 
                    ?
                      documents?.AddressProof.map((file, i)=> {
                        return(
                          <div key= {i}> 
                            {
                                ((file.url).split('.').pop() ==="png" || (file.url.split('.').pop() === "jpg") || (file.url).split('.').pop() ==="PNG" || (file.url.split('.').pop() === "JPG"))
                                ? 
                                  <img
                                    key ={i} style={{ width: 500, height: 400, margin: 10 }}
                                    src={file && file?.url}
                                    onClick={()=>download(file && file?.url,'AddressProof')}
                                    ></img> 
                                :
                                  ""
                            }  
                            {
                                ((file.url).split('.').pop() ==="pdf" || (file.url.split('.').pop() === "PDF"))
                                ? 
                                  <a href={file?.url} key = {i} className="mt" onClick={()=>download(file && file?.url,'AddressProof')}><img className="fileExt"  alt="AddressProof" src="/images/pdf.png"/> </a>
                                :
                                ""
                            } 

                          </div> 
                        )
                      })
                    :""
                  } 
                </td>
              </tr>
              <tr>
                <td>GST Registration Certificate</td>
                <td> 
                  {
                    documents?.GSTDocument && documents?.GSTDocument.length
                    ?
                      documents?.GSTDocument.map((file, i)=> {
                        {/*console.log("file.url",file.url)*/}
                          return(
                            <div key= {i}> 
                              {
                                  ((file.url).split('.').pop() ==="png" || (file.url.split('.').pop() === "jpg") || (file.url).split('.').pop() ==="PNG" || (file.url.split('.').pop() === "JPG"))
                                   ? 
                                    <img
                                      key ={i} style={{ width: 500, height: 400, margin: 10 }}
                                      src={file && file?.url}
                                      onClick={()=>download(file && file?.url,'GSTDocument')}
                                      ></img> 
                                  :
                                    <a href={file?.url} key = {i} className="mt" onClick={()=>download(file && file?.url,'GSTDocument')}><img className="fileExt"  alt="GSTDocument" src="/images/pdf.png"/> </a>
                              } 

                            </div> 
                          )
                      })
                    :
                      ""
                  } 
                </td>
              </tr>
              <tr>
                <td>Pictures of Office and Staff</td>
                <td> 
                  {
                    documents?.officeStaffPics && documents?.officeStaffPics.length
                    ?
                      documents?.officeStaffPics.map((file, i)=> {
                        {/*console.log("file.url",file.url)*/}
                          return(
                            <div key= {i}> 
                              {
                                  ((file.url).split('.').pop() ==="png" || (file.url.split('.').pop() === "jpg") || (file.url).split('.').pop() ==="PNG" || (file.url.split('.').pop() === "JPG"))
                                   ? 
                                    <img
                                      key ={i} style={{ width: 500, height: 400, margin: 10 }}
                                      src={file && file?.url}
                                      onClick={()=>download(file && file?.url,'OfficeStaffPics')}
                                      ></img> 
                                  :
                                    <a href={file?.url} key = {i} className="mt" onClick={()=>download(file && file?.url,'OfficeStaffPics')}><img className="fileExt"  alt="OfficeStaffPics" src="/images/pdf.png"/> </a>
                              } 

                            </div> 
                          )
                      })
                    :""
                  } 
                </td>
              </tr>
              <tr>
                <td>Slide Show of the Profile/Services</td>
                <td> 
                  {
                    documents?.slideShow && documents?.slideShow.length
                    ?
                      documents?.slideShow.map((file, i)=> {
                        {/*console.log("file.url",file.url)*/}
                          return(
                            <div key= {i}> 
                              {
                                  ((file.url).split('.').pop() ==="png" || (file.url.split('.').pop() === "jpg") || (file.url).split('.').pop() ==="PNG" || (file.url.split('.').pop() === "JPG"))
                                   ? 
                                    <img
                                      key ={i} style={{ width: 500, height: 400, margin: 10 }}
                                      src={file && file?.url}
                                      onClick={()=>download(file && file?.url,'slideShow')}
                                      ></img> 
                                  :
                                    <a href={file?.url} key = {i} className="mt" onClick={()=>download(file && file?.url,'slideShow')}><img className="fileExt"  alt="slideShow" src="/images/pdf.png"/> </a>
                              } 

                            </div> 
                          )
                      })
                    : ""
                  } 
                </td>
              </tr>
            </tbody>
          : ""
        }
      </table>

    </section>
  </>
}