import React,{Component} from 'react';
import { render } from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import './StatusComponent.css';

export default class StatusComponent extends Component{
  
  constructor(props) {
   super(props);
    this.state = {

    }
  }
   
  componentDidMount(){
 
}

    
  render(){
    // console.log("this.props.stats",this.props.stats)
    return(
        <main className="col-lg-4  col-md-6 col-xs-12 col-sm-12 ">
            <div className="col-lg-12  col-md-12 col-xs-12 col-sm-12 mainicon" >
          <div className="row">
            
            <div className="col-lg-12  col-md-12 col-xs-12 col-sm-12 statusHeading statusBox text-center" style={{backgroundColor:this.props.stats.color}}>{this.props.stats.heading1}</div>
            <div className="col-lg-12  col-md-12 col-xs-12 col-sm-12 NOPadding" >
              <div className="col-lg-6  col-md-6 col-xs-12 col-sm-12 statusHeading2  statusBox2">Consultants:<b> {this.props.stats.value1 ? this.props.stats.value1 : 0}</b></div>
              <div className="col-lg-6  col-md-6 col-xs-12 col-sm-12 statusHeading3 statusBox3">Collections:<b> Rs. {this.props.stats.value2 ? this.props.stats.value2 : 0}</b></div>
            </div>
            </div>
          </div>
        </main>  
        );
  }
}


/* {

                  this.props.stats.multipleValues ?  
                    <React.Fragment>
                      <div> <b> Centers : {this.props.stats.centerCount}</b> </div>
                       {this.props.stats.centerData && this.props.stats.centerData.length > 0 ?
                        this.props.stats.centerData.map((center,i)=>{
                          return(  
                            <div className="dashboardHeading1" key={i}>{center.typeOfCenter.split(' ')[0]}  : <strong>{center.count}</strong></div>
                          )
                        })
                        : null
                      }
                    </React.Fragment>
                  :
                    <div>
                      <div className="dashboardHeading">{this.props.stats.heading1}</div>
                      <div className="per"><strong>{this.props.stats.value1}</strong></div>
                      <div className="dashboardHeading">{this.props.stats.heading2}</div>
                      <div className="per"><strong>{this.props.stats.value2}</strong></div>
                    </div>              
                }*/