import React, { Component } from 'react';
import $ from 'jquery';
import ReportsTemplate from './ReportsTemplate.js';
import axios from 'axios';
import "./Reports.css";
import 'font-awesome/css/font-awesome.min.css';

class ConsultantIntroductoryCalls extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vendorArray 			    : [],
			showDateWiseFilters		    : true, // to show DateWise filters set true else set false
			filterObject			    : {
				'dailyFilter'		    : true, // to show Daily Filter set true else set false
				'weeklyFilter'		    : true, // to show Weekly Filter set true else set false
				'monthlyFilter'		    : true, // to show Monthly Filter set true else set false
				'yearlyFilter'		    : true, // to show Yearly Filter set true else set false
				'customizedDateFilter'  : true // to show Customized Date Filter set true else set false
			},
			showCustomizedFilters	    : false, // to add other customized filters set true else set false

			customizedFiltersArray      : [
				//Array to add different customized filters
				{
					'inputLabel'        : "Status",
					'inputType'			: "",
					'inputDefaultValue' : "--Select--",
					'inputPlaceholder'	: "",
					'inputName'			: "status",
					'inputArray'		: [
						{ name: 'status', value: 'New', label: 'New' },
						{ name: 'status', value: 'Processing', label: 'Processing' },
						{ name: 'status', value: 'Ready to Dispatch', label: 'Ready to Dispatch' },
						{ name: 'status', value: 'On The Way', label: 'On The way' },
						{ name: 'status', value: 'Delivered', label: 'Delivered' },
						{ name: 'status', value: 'Cancelled', label: 'Cancelled' }
					],
					'apiUrl'			: "/api/"
				}

			],
			'currentActiveTab'			: "Daily", //If showDateWiseFilters is true then set cuttentActiveTab 'Daily' or 'Weekly' or 'Monthly' or 'Yearly' or 'Customize' or leave it ""
			'reportTitle'				: "Consultant Introductory Calls", // Title or Heading of report
			'tableName'					: "Consultant_Introductory_Calls", // Title or Heading of report
			'tableDatas'				: [],
			'reportData'				: {},
			'tableData'					: [],
			"startRange"				: 0,
			"limitRange"				: 10,
			"dataApiUrl"				: "/api/reports/post/consultant-introductory-calls",
			"tableHeading"				: {
				srNo                                     : 'Sr No',
				userID                                   : 'User ID',
				userName                                 : 'User Name',
				dateofBooking 						     : "Date of Booking",
				dateofIntroductoryCall	                 : "Date of Introductory Call",
				consultantID 						     : "Consultant ID",
				consultantName 					         : "Consultant Name",
				enterpriseName						     : 'Enterprise Name',
				consultantLocation                       : "Consultant Location",
				professionMainCategory                   : "Profession Main Category",
				timeSlot                                 : "Time Slot",
				userLocation                             : "User Location",
				userBusinessCategory 				     : "User Business Category",
				timeAtWhichConsultantLoggedInForTheCall  : "Time at which consultant Logged In for the call",
				timeAtWhichUserLoggedInForTheCall		 : "Time at which User Logged In for the call",
				durationOfTheCall 						 : "Duration of the call",
				introductoryCallTokenID 				 : "Introductory Call Token ID",
				fees 									 : "Fees",
				whetherPaymentSettledAtThePlatform       : "Whether payment settled at the platform",
				paymentReferenceID                       : "Payment Reference ID",
				// callQualityRatingByTheConsultant         : "Call Quality Rating by the Consultant",
				callQualityRatingByTheUser		         : "Call Quality rating by the User",
				whetherCallCancelled                     : "Whether call cancelled",
				dateOfCancellation                       : "Date of Cancellation",
				timeOfCancellation                       : "Time of Cancellation",
				whoCancelledTheCall                      : "Who cancelled the call",
				ReasonForCancellation                    : "Reason For Cancellation",
				whetherCallRescheduled                   : "Whether call re-scheduled",
				whoRescheduledTheCall                    : "Who re-scheduled the call",
				newTimeSlot                              : "New Time Slot",
			},
			tableObjects: {
				paginationApply         : true,
				searchApply 			: true,
				excelReportExport		: false,
				searchApplyPlaceholder 	: "Search by Consultant Name",
			},
		}
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<ReportsTemplate
				tableHeading 				={this.state.tableHeading}
				tableObjects    			={this.state.tableObjects}
				showDateWiseFilters 		={this.state.showDateWiseFilters}
				filterObject 				={this.state.filterObject}
				showCustomizedFilters		={this.state.showCustomizedFilters}
				customizedFiltersArray		={this.state.customizedFiltersArray}
				currentActiveTab			={this.state.currentActiveTab}
				reportTitle					={this.state.reportTitle}
				dataApiUrl					={this.state.dataApiUrl}
			/>
		);
	}
}

export default ConsultantIntroductoryCalls