import React,{Component}      from 'react';
import moment                 from 'moment-timezone';
import axios                  from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import './TableComponent.css';

export default class TableComponent extends Component{
  
   constructor(props) {
      super(props);
      this.state = {
         introConsData : this.props.introConsData ? this.props.introConsData : []
      }
   }
   
   componentDidMount(){
      // console.log("this.props componentDidMount",this.props)
      this.props.getData(this.props.fromDate, this.props.toDate)
        
   }
   componentWillReceiveProps(nextProps){
      // console.log("this.props componentWillReceiveProps",this.props)
      this.getTableData(this.props.api, this.props.fromDate, this.props.toDate)
   }


   getTableData(api,fromDate,toDate){
      var limit =10;
      axios.get(api+fromDate+"/"+toDate+"/"+limit)
         .then(introConsData => {            
            // console.log("introConsData => ",introConsData);
            this.setState({
               introConsData          : introConsData.data.data,
            })
         })
         .catch(error=>{
             console.log("error => ",error);
         })
   }
   isApmtTimeGreater(apmtTime){
      const currTime = moment().tz('Asia/Kolkata').format("HH:mm").toUpperCase();
      // console.log(" && apmtTime => ", apmtTime);

      if(apmtTime.includes('PM') ){
         var atArr   = apmtTime.split(" ");
         var atTime  = atArr[0].split(":");
         if(atTime[0] ==12){
            var atHr    = parseInt(atTime[0]);
            var at      = atHr +":"+ atTime[1];
         }else{
            var atHr    = parseInt(atTime[0]) + 12;
            var at      = atHr +":"+ atTime[1];
         }
      }else if(apmtTime.includes('AM')){
         var at = apmtTime.substr(0,apmtTime.length-2).trim();
      }else{
         var at = apmtTime;
      }

      // console.log("apmtTime => ",at);
      // console.log("at >= currTime",at, currTime,at >= currTime)
      if(at >= currTime){
         return true;
      }else{
         return false;
      }
   }


  render(){
    return(
    <div className="col-lg-12 marginTop40">
      <div className="col-lg-12 emptybox1 "></div>

         <div className="col-lg-12 box7">
            <div className="row ">
               <div className="box1a col-lg-12">
                  <div className="row">
                     <div className="col-lg-12 ">
                        <div><h4>{this.props.title}</h4></div>   
                     </div>
                  </div>
               </div>
               <div className="col-lg-12">
                  <table className="table no-margin">
                     <thead>
                        <tr>
                           <th>Sr. No</th>
                           <th>Token Number</th>
                           <th>Consultant Name</th>
                           <th>Consultant Location</th>
                           <th>User Name</th>
                           <th>Call Status</th>
                           <th>Call Date</th>
                           <th>Time Slot</th>
                           <th>Fees Paid</th>
                        </tr>
                     </thead>
                     <tbody>
                        {
                           this.state.introConsData && this.state.introConsData.length>0 ? 
                              this.state.introConsData.map((item,index)=>{
                                 var currentDate = moment().tz('Asia/Kolkata').format("YYYY-MM-DD");
                                 {/*console.log("item.isoAppointmentDate==currentDate",item.isoAppointmentDate==currentDate,item.isoAppointmentDate,currentDate)*/}
                                 {/*console.log("moment(item.isoAppointmentDate).diff(moment(currentDate)) >= 0",moment(item.isoAppointmentDate).diff(moment(currentDate)) >= 0)*/}
                                 {/*console.log("moment(item.isoAppointmentDate).diff(moment(currentDate))",moment(item.isoAppointmentDate).diff(moment(currentDate)))*/}
                                 if(item.isoAppointmentDate==currentDate){
                                    var diff = this.isApmtTimeGreater(item.appointmentTime)
                                    {/*console.log("diff",diff,index)*/}
                                    {/*console.log("item1",item.status)*/}
                                    if(!diff && item.status=="pending"){
                                       item.status= "completed"
                                       {/*console.log("item2",item.status)*/}
                                    }
                                 }else if(moment(item.isoAppointmentDate).diff(moment(currentDate)) <= 0){
                                       item.status= "completed"
                                       {/*console.log("item3",item.status)*/}
                                 }
                                 return (
                                    <tr key={index}>
                                       <td>{index+1}</td>
                                       <td>{item.tokenNumber}</td>
                                       <td>{item.consultantName}</td>
                                       <td>{item.city}</td>
                                       <td>{item.userName}</td>
                                       <td>
                                          {
                                             item.status== "pending" ? 
                                                <span className="label label-warning box7label">Pending</span>
                                             :
                                                item.status== "completed" ? 
                                                   <span className="label label-success box7label">Completed</span>
                                                   :
                                                     item.status== "rescheduled" ? 
                                                         <span className="label label-info box7label">Rescheduled</span>
                                                   :
                                                     item.status== "cancelled" ? 
                                                         <span className="label label-danger box7label">Cancelled</span>
                                                   : null
                                          }
                                       </td>
                                       <td>{moment(item.isoAppointmentDate).format("DD-MM-YYYY")}</td>
                                       <td>{item.appointmentTime + " to " + item.appointmentEnd}</td>
                                       <td>{item.fees}</td>
                                    </tr>
                                 )

                              })
                           : <tr>No data available</tr>
                        }
                     </tbody>
                  </table>
                  <div className="col-lg-12 box7foot">
                     <div className="row"> 
                        <div className="col-lg-12 emptybox2 "></div>  
                        <a href="#" className="btn box7btn btn-sm btn-info btn-flat pull-left">Place New Order</a>
                        <a href="#" className="btn box7btn btn-sm btn-default btn-flat pull-right">View All Orders</a>
                     </div>
                  </div>
                  <div className="col-lg-12 emptybox1 "></div>
               </div>
            </div>
         </div>
          {/*<div className="col-lg-12">
            <table className="table no-margin">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Item</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><a href="#">OR9842</a></td>
                    <td>Call of Duty IV</td>
                    <td><div className="label label-success box7label">Shipped</div></td>
                    <td>
                    </td>
                  </tr>
                  <tr>
                    <td><a href="#">OR1848</a></td> 
                    <td>Samsung Smart TV</td>
                    <td><span className="label label-warning box7label">Pending</span></td>
                    <td>
                    </td>
                  </tr>
                  <tr>
                    <td><a href="#">OR7429</a></td>
                    <td>iPhone 6 Plus</td>
                    <td><span className="label label-danger box7label">Delivered</span></td>
                    <td>
                    </td>
                  </tr>
                  <tr>
                    <td><a href="#">OR7429</a></td>
                    <td>Samsung Smart TV</td>
                    <td><span className="label label-info box7label">Processing</span></td>
                    <td>
                    </td>
                  </tr>
                  <tr>
                    <td><a href="#">OR1848</a></td>
                    <td>Samsung Smart TV</td>
                    <td><span className="label label-warning box7label">Pending</span></td>
                    <td>
                    </td>
                  </tr>
                  <tr>
                    <td><a href="#">OR7429</a></td>
                    <td>iPhone 6 Plus</td>
                    <td><span className="label label-danger box7label">Delivered</span></td>
                    <td>
                      
                    </td>
                  </tr>
                  <tr>
                    <td><a href="#">OR9842</a></td>
                    <td>Call of Duty IV</td>
                    <td><span className="label label-success box7label">Shipped</span></td>
                    <td>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div className="col-lg-12 box7foot">
                  <div className="row"> 
                  <div className="col-lg-12 emptybox2 "></div>  
                    <a href="#" className="btn box7btn btn-sm btn-info btn-flat pull-left">Place New Order</a>
                    <a href="#" className="btn box7btn btn-sm btn-default btn-flat pull-right">View All Orders</a>
                  </div>
                  
                </div>
         </div>*/}
   </div>

      );
  }
}
