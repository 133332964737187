import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";

export default () => {
  const [consultantProfiles, setBlogDetails] = useState([]);

  useEffect(() => {
    fetchBlogsforApproval();
  }, []);

  const fetchBlogsforApproval = () => {
    axios
      .get("/api/blogs2/blogslist")
      .then((response) => {
        // console.log("response =====> ", response);
        setBlogDetails(response.data.data);
      })
      .catch((error) => {
        console.log("Error => ", error);
      });
  };
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding">
      <section className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding content">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pageContent table-responsive">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 NOPadding topMargin box-header with-border">
              <h4 className="col-lg-12 col-md-12 col-xs-12 col-sm-12 weighttitle NOPadding">
                Approval of Blogs
              </h4>
            </div>
          </div>
          <Table
            data={consultantProfiles}
            fetchBlogsforApproval={fetchBlogsforApproval}
          />
        </div>
      </section>
    </div>
  );
};

const Table = (props) => {
  const [loader, setLoader] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [filter, setFilter] = useState({});

  const columns = [
    {
      key: "blogTitle",
      name: "Blog Title",
      colSize: "w100",
      type: "string",
      isSortable: true,
      renderHTML : false,
      children: (props) => <TitleHref {...props} />,      
    },
    {
      key: "blogCategory",
      name: "Blog Category",
      colSize: "w100",
      type: "string",
      isSortable: true,
      renderHTML : false
    },
    {      
      key: "blogSummary",
      name: "Blog Summary",      
      colSize: "w300",
      type: "string",
      isSortable: true,
      renderHTML : true
    },
    // {
    //   key: "blogContent",
    //   name: "Blog Content",
    //   colSize: "w200",
    //   type: "string",
    //   isSortable: true,
    //   renderHTML : true
    // },
    {
      key: "userFullName",
      name: "User Name",
      colSize: "w100",
      type: "string",
      isSortable: true,
      renderHTML : false
    },
    {
      key: "approvalStatus",
      name: "Status",
      colSize: "w100",
      type: "string",
      isSortable: true,
      renderHTML : false,
      children: (props) => <ShowStatus {...props} />,      
    },
    {
      key: "",
      name: "Action",
      colSize: "w100",
      type: "string",
      isSortable: true,
      renderHTML : false,
      children: (props) => <ModalBtn {...props} />,
    },      
    {
      key: "remark",
      name: "Remark",
      colSize: "w100",
      type: "string",
      isSortable: true,
      renderHTML : false,
    }, 
  ];

  const [page, setPage] = useState(1);
  let pages = 2;
  const getPaginatedEntries = () => {
    pages = props.data.length > 0 ? props.data.length - 1 : 1;
    return props.data.slice((page - 1) * pageLimit, page * pageLimit);
  };

  const handlePageLimit = (e) => {
    setPageLimit(parseInt(e.target.value));
  };

  const dataRows = getPaginatedEntries();

  const handleSort = (event) => {
    setFilter({
      ...filter,
      sort: { key: event.target.id, type: filter?.sort?.type === 1 ? 0 : 1 },
    });
  };

  return (
    <div className="pageContent px-5  pt-1">
      <div className="justify">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 table-responsive">
          <table className="col-lg-12 col-md-12 col-sm-12 col-xs-12 table table-hover table-bordered table-striped table-height tableFixHead">
            <thead className="tempTableHeader">
              <tr>
                <th scope="col">#</th>
                {columns.map((column,i) => (
                  <th
                    key={i}
                    scope="col"
                    className={` ${filter?.sort?.key === column.key &&  "text-primary" 
                      }  text-uppercase `}
                  >
                    {column.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataRows.length > 0 ? (
                dataRows.map((data, index) => (
                  <tr key={index}>
                    <td className="text-center align-middle">
                      {page === 1
                        ? index + 1
                        : `${(page === 1 ? 1 : page - 1) * pageLimit + (index + 1)
                        }`}
                    </td>
                    {columns.map((column, index) => (
                      <td
                        key={index}
                        className={column.colSize &&` ${column.key === "approvalStatus" ? "text-center" : "text-left"
                          } align-middle`}
                      >
                        {
                          column.children 
                          ? 
                            (
                              <column.children
                                fetchBlogsforApproval={
                                  props.fetchBlogsforApproval
                                }
                                data={data}
                                className={column.colSize}
                                title={data[column.key] ?? ""}
                              />
                            ) 
                        : 
                          column.renderHTML 
                          ?
                            <p className={"text-justify "  && column.colSize} dangerouslySetInnerHTML={ { __html:data[column.key]}}></p>
                          : 
                            (data[column.key])
                      }
                      </td>                     
                    ))}
                  </tr>
                ))
              ) 

              :
                (
                  <tr className="trAdmin">
                  {loader ? (
                    <div className="loadingImg">
                      <img src="/images/loading.gif" />
                    </div>
                  ) : (
                    <td colSpan="9" className="noTempData textAlignCenter">No Record Found!</td>
                  )}
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
        <TablePagination
          currentPage={page}
          pages={pages}
          setPage={setPage}
          pageLimit={pageLimit}
          handlePageLimit={handlePageLimit}
        />
      </div>
    </div>
  );
};

const TablePagination = ({
  currentPage,
  pages,
  setPage,
  pageLimit,
  handlePageLimit,
}) => {
  const numPages = Math.ceil(pages / pageLimit);

  const renderPageBlocks = () => {
    return (
      <>
        {currentPage !== 1 && (
          <li className="page-item">
            <a className="page-link" href="#" onClick={goToPrevPage}>
              {currentPage - 1}
            </a>
          </li>
        )}
        <li className="page-item active" disabled>
          <a className="page-link" href="#">
            {currentPage}
          </a>
        </li>
        {currentPage < numPages && (
          <li className="page-item">
            <a className="page-link" href="#" onClick={goToNextPage}>
              {currentPage + 1}
            </a>
          </li>
        )}
      </>
    );
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < numPages) {
      setPage(currentPage + 1);
    }
  };

  const renderPrevPageBlocks = () => {
    return (
      <li className="page-item">
        <a className="page-link" href="#" onClick={() => setPage(1)}>
          &#171;
        </a>
      </li>
    );
  };

  const renderNextPageBlocks = () => {
    return (
      <li className="page-item">
        <a className="page-link" href="#" onClick={() => setPage(numPages)}>
          &raquo;
        </a>
      </li>
    );
  };

  const renderTotals = () => {
    return (
      <li className="mx-2 col-8">
        <h6>{`Showing ${currentPage} of ${numPages}`} Pages</h6>
      </li>
    );
  };

  return (
    <nav aria-label="Page navigation" className="text-center align-middle mb-5">
      <ul className="pagination col-12 d-flex justify-content-center flex-direction-row">
        {renderTotals()}
        {renderPrevPageBlocks()}
        {renderPageBlocks()}
        {renderNextPageBlocks()}
      </ul>
    </nav>
  );
};


const ModalBtn = (props) => {
  // console.log(props);
   const handleClick = () => {
      swal("Remark:", {
         content: "input",
         attributes: {
         placeholder: 'Please provide reason.',
         required: true
      },
      closeOnClickOutside: false,
      button: {
        text: "Save",
      },
      }).then((value) => {
         handleApprovalFeedback("Rejected", value);
      });
   }

   const handleApprovalFeedback = (status, remark) => {
      console.log("props.data",props.data)
      var formValues = {
         blog_id         : props.data._id,
         blogTitle       : props.data.blogTitle,
         status          : status,
         remark          : remark,
         email           : props.data.email,
         mobile          : props.data.mobile,
         userFullName    : props.data.userFullName,
      };

      axios.patch("/api/blogs2/approveBlog",formValues)
      .then((response) => {
         if (response.data.success) {
            swal(" ", "Response submitted successfully");
            props.fetchBlogsforApproval();
         }else{
            swal(" ", "Response not submitted.");
         }
      })
      .catch((error) => {
        console.log("Error => ", error);
        swal(" ", "Response not submitted.");
      });
  };

   if(props.data.approvalStatus === "Pending"){
      return <div style={{"width":"150px"}}>
         <button className='btn btn-success col-2' onClick={() => handleApprovalFeedback('Approved')} >Approve</button> &nbsp;
         <button className='btn btn-danger col-2' onClick={handleClick}>Reject</button>
      </div>;      
   }else{
      return null ;
   }

};


const ShowStatus = (props)=>{
   console.log("props.data => ",props.data.approvalStatus);

   if(props.data.approvalStatus === "Approved"){
      var textColour = "#28a745";
   }
   if(props.data.approvalStatus === "Rejected"){
      var textColour = "#dc3545";
   }
   if(props.data.approvalStatus === "Pending"){
      var textColour = "#ffc107";
   }
   return <div style={{fontWeight : "bold",fontSize:'15px',color:textColour}}> {props.data.approvalStatus} </div>
}

const TitleHref = (props)=>{
   
   // console.log("props.data => ",props.data);

   return <a className = "w200"  href={"/blogs/"+props.data.blogURL} target="_blank"> {props.data.blogTitle} </a>
}

