import React, { Component, useState, useEffect  }  from 'react';
import axios                                       from 'axios';
import $                                           from 'jquery';
import DetailedBlog                                from './DetailedBlog.js';
import { useParams }                               from "react-router-dom";
import Swal                                        from "sweetalert2";

const BlogPage = (props)=>{

   const [blogData, setBlogData] = useState();

   const {blogurl} = useParams();
   // console.log("blogurl => ",blogurl);

   useEffect(()=>{
      getBlogData()
   },[]);
   
   const getBlogData = () => {
      // console.log("blogurl => ",blogurl)
      axios.get("/api/blogs2/get/"+blogurl)
      .then((response) => {
         console.log("response.data => ",response.data);
         if(response.data.message !== "DATA_NOT_FOUND"){
            setBlogData(response.data);
         }else{
            Swal.fire("Blog data not found","Please contact System Admin","error");
         }
      })
      .catch((error) => {
        console.log("Error => ", error);
      });
   }


   return (
      <div className={"container-fluid px-0"}>
         {/*{console.log("blogData => ",blogData)}*/}
         
         {
            typeof blogData !== 'undefined'
            ?
               <DetailedBlog blogData={blogData} />
            :
               <div className="col-xs-6 col-xs-offset-3" style={{marginTop:"50px"}}>
                  <img src="/images/data-not-found.jpg" alt="loading" width="500px"/> 
                  <div style={{textAlign:"center",marginTop:"30px",fontSize:"21px"}}> <big><b> Blog page content not found </b></big> </div>
               </div>
         }
      </div>
  )
}



export default BlogPage;

