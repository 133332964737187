import React, { Component }                       from 'react';
import { connect }                                from 'react-redux';
import withRouter                                 from './eadvicer/common/withRouter.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';


// Section: 1 - SystemSecurity ******************************************************
import Login            from './eadvicer/systemSecurity/Login.js';
import ConfirmOtp       from './eadvicer/systemSecurity/ConfirmOtp.js';
import ForgotPassword   from './eadvicer/systemSecurity/ForgotPassword.js';
import ResetPassword    from './eadvicer/systemSecurity/ResetPassword.js';
import SignUp           from './eadvicer/systemSecurity/SignUp.js';

import Header           from './eadvicer/common/header/Header.js'; 
import Footer           from './eadvicer/common/footer/Footer.js';
import Leftsidebar      from './eadvicer/common/leftSidebar/Leftsidebar.js';
import CoreLayout       from './eadvicer/CoreLayout/CoreLayout.js';

// ============= Master =======================
import MasterData       from "./eadvicer/MasterData/MasterData.js";

// ============= Reports =======================
import ConsultantDataReport                  from "./eadvicer/Reports/1_consultantDataReport.js";
import ConsultantIntroCallsReport            from "./eadvicer/Reports/2_consultantIntroCalls";
import ProfileEditingReport                  from './eadvicer/Reports/3_ProfileEditingReport.js';
import EnterpriseListingReprt                from './eadvicer/Reports/4_EnterpriseListingReport.js';
import LeadsGeneratedReport                  from './eadvicer/Reports/5_LeadsGeneratedReport.js';
import ReviewRatingReport                    from './eadvicer/Reports/6_ReviewRatingReport.js';
import ConsultantComplaintReport             from './eadvicer/Reports/7_ConsultantComplaintReport.js';
import ConsultantSettlementReport            from './eadvicer/Reports/8_ConsultantSettlementReport.js';
import ListingFeesReport                     from './eadvicer/Reports/9_ListingFeesReport.js';
import FeedbackReport                        from './eadvicer/Reports/10_FeedbackReport.js';
import UserDataReport                        from './eadvicer/Reports/11_UserDataReport.js';
import UserIntroductoryCallsReport           from './eadvicer/Reports/12_UserIntroductoryCallsReport.js';
import UserComplaintsReport                  from './eadvicer/Reports/13_UserComplaintsReport.js';

// ==============================================
import ConsultantProfileApprovalTable        from './eadvicer/ProfileApprovalTable/ConsultantProfileApprovalTable.js'
import EnterpriseProfileApprovalTable        from './eadvicer/ProfileApprovalTable/EnterpriseProfileApprovalTable'
import ConsultantSubCategoryApproval         from './eadvicer/CategoryApprovalTable/ConsultantSubCategoryApproval.js';
import ConsultantExpertiseCategoryApproval   from './eadvicer/CategoryApprovalTable/ConsultantExpertiseCategoryApproval.js';
import EnterpriseExpertiseCategoryApproval   from './eadvicer/CategoryApprovalTable/EnterpriseExpertiseCategoryApproval.js';
import EnterpriseSubCategoryApproval         from './eadvicer/CategoryApprovalTable/EnterpriseSubCategoryApproval.js';
import BlogsApproval                         from "./eadvicer/BlogsApproval/BlogApproval.js";
import BlogPage                              from "./eadvicer/BlogsApproval/BlogPage/BlogPage.js";
import SEOPanel                              from "./eadvicer/SEOPanel/SEOPanel.js";


class Layout extends Component  {

    constructor(props) {
        super();
        this.state = {
            loggedIn: false,
        }
    }

    componentDidMount() {
        const token = localStorage.getItem("token");
        const roles = localStorage.getItem("roles");
        if (token !== null && token !== "undefined") {
            this.setState({
                loggedIn: true,
                roles:roles
            })
        } else { }

    }

    logout() {
        var token = localStorage.removeItem("token");
        if (token !== null && token !== "undefined") {
            this.setState({
                loggedIn: false
            })
        }
    }

    render() {
        if(this.state.loggedIn) {
            return (
                <Router>
                {this.state.roles!=='digitalmarketing'
                ?
                    <div className="hold-transition skin-blue fixed sidebar-mini">
                        <div className="content-wrapper">
                            <div className="wrapper">
                                <Header />
                                <div className="">
                                    <div className="row">
                                        <Leftsidebar/>
                                        <div className="container-fluid main-container">
                                            <div className="row">
                                                <div className="dashboardWrapper" >
                                                    <div className="backColor col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                                                        <CoreLayout />
                                                        <Routes >
                                                            {/* Master Data */}

                                                            <Route path="/project-master-data"          element={<MasterData render={(props) => ({ ...props })}/> } exact />
                                                            <Route path="/project-master-data/:editId"  element={<MasterData render={(props) => ({ ...props })}/> } exact />
                                                            <Route path="/project-master-data/oneField/:oneFieldEditId" element={<MasterData render={(props) => ({ ...props })}/> } exact />
                                                            
                                                            {/*<Route path="/project-master-data"          render={(props)=><MasterData {...props}/> } exact />
                                                            <Route path="/project-master-data/:editId"  render={(props)=><MasterData {...props}/> } exact />
                                                            <Route path="/project-master-data/oneField/:oneFieldEditId" render={(props)=><MasterData {...props}/> } exact />
                                                        */}
                                                            <Route path="/profile_approval/consultant"          element={<ConsultantProfileApprovalTable/>} exact />
                                                            <Route path="/profile_approval/enterprise"          element={<EnterpriseProfileApprovalTable/>} exact />
                                                            <Route path="/other_sub_catg/enterprise"            element={<EnterpriseSubCategoryApproval/>} exact />
                                                            <Route path="/other_enterprise_catg/enterprise"     element={<EnterpriseExpertiseCategoryApproval/>} exact />
                                                            <Route path="/other_sub_catg/consultant"            element={<ConsultantSubCategoryApproval/>} exact />
                                                            <Route path="/other_expertise_catg/consultant"      element={<ConsultantExpertiseCategoryApproval/>} exact />
                                                            
                                                            <Route path="/blogs-approval"                       element={<BlogsApproval />} exact />
                                                            <Route path="/blogs/:blogurl"                       element={<BlogPage />} exact />

                                                            <Route path="/seo-panel"                            element={<SEOPanel /> } exact />
                                                            <Route path="/seo-panel/:editId"                        element={<SEOPanel /> } exact />

                                                            {/* Reports */}
                                                            <Route path="/reports/consultant-data"              element={<ConsultantDataReport /> } exact />
                                                            <Route path="/reports/consultant-introductory-calls"element={<ConsultantIntroCallsReport /> } exact />
                                                            <Route path="/reports/profile-editing"              element={<ProfileEditingReport /> } exact />
                                                            <Route path="/reports/enterprise-listing"           element={<EnterpriseListingReprt /> } exact />
                                                            <Route path="/reports/leads-generated"              element={<LeadsGeneratedReport /> } exact />
                                                            <Route path="/reports/review-ratings"               element={<ReviewRatingReport /> } exact />
                                                            <Route path="/reports/consultants-complaints"       element={<ConsultantComplaintReport /> } exact />
                                                            <Route path="/reports/consultants-settlement"       element={<ConsultantSettlementReport /> } exact />
                                                            <Route path="/reports/user-data"                    element={<UserDataReport /> } exact />
                                                            <Route path="/reports/user-introductory-calls"      element={<UserIntroductoryCallsReport /> } exact />
                                                            <Route path="/reports/user-complaints"              element={<UserComplaintsReport /> } exact />
                                                            <Route path="/reports/listing-fees"                 element={<ListingFeesReport /> } exact />
                                                            <Route path="/reports/feedback-report"              element={<FeedbackReport /> } exact />
                                                        </Routes>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div> 
                : 
                <div className=" skin-blue sidebar-mini">
                    <div className="">
                        <Header />
                        <Routes >
                            <Route path="/"                                     element={<SEOPanel /> } exact />
                            <Route path="/seo-panel"                            element={<SEOPanel /> } exact />
                            <Route path="/seo-panel/:editId"                    element={<SEOPanel /> } exact />
                        </Routes >
                    </div>
                    <Footer />
                </div>

                }
                </Router>
            );
        } else {
            return (
                <div>
                    <Router >
                            <Routes >
                                <Route path="/" exact strict element={<Login/>} />
                                <Route path="/login" exact strict element={<Login/>} />
                                <Route path="/signup" exact strict element={<SignUp/>} />
                                <Route path="/forgotpassword" exact strict element={<ForgotPassword/>} />
                                <Route path="/reset-pwd/:user_ID" exact strict element={<ResetPassword/>} />
                                <Route path="/confirm-otp/:userID" exact strict element={<ConfirmOtp/>} />
                            </Routes>
                    </Router>
                </div>
            );
        }
    }
}

 
export default Layout;